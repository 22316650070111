import { Disc, FolderOpen, Sparkles, Zap } from "lucide-react";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const featuresList = [
    {
        icon: Sparkles,
        text: (
            <p>
                <b>יצירת תכנים עם AI</b> - כדי לעזור לכם לדעת מאיפה להתחיל
            </p>
        ),
    },
    {
        icon: FolderOpen,
        text: (
            <p>
                <b>אחסון</b> - מקום פשוט ונגיש לכל התכנים שלכם כדי שתוכלו לשתף
                אותם בקלות
            </p>
        ),
    },
    {
        icon: Zap,
        text: (
            <p>
                <b>שאלות אינטראקטיביות</b> - הפכו את התכנים שלכם לאינטראקטיביים
                ומעניינים
            </p>
        ),
    },
];

export default function PaymentWall() {
    return (
        <div className="flex-1 flex flex-col w-full justify-center px-4 py-4">
            <h2 className="text-3xl tracking-tight font-bold">
                שדרגו את המנוי
            </h2>
            <p className="mt-2 text-base text-gray-700">
                נגמרו לכם הקרדיטים במנוי - כדי להמשיך תצטרכו לשדרג אותו. בידע
                תמצאו את כל הכלים שאתם צריכים להצלחה:
            </p>
            <div className="mt-4 flex flex-col gap-4">
                {featuresList.map((feature, idx) => (
                    <div key={idx} className="flex flex-row items-center gap-2">
                        <feature.icon className="size-5 text-primary flex-shrink-0" />
                        {feature.text}
                    </div>
                ))}
            </div>
            <a
                href="/pricing"
                className={cn(buttonVariants({ variant: "ai" }), "mt-6 w-full")}
            >
                לצפייה במסלולים
            </a>
            <p className="mt-2 text-sm text-muted-foreground text-center">
                אפשר לבטל את המנוי בכל זמן
            </p>
        </div>
    );
}
