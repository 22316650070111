"use client";

import {
    videoWorkflowCollectionName,
    VideoWorkflowStep,
    VideoWorkflowStepName,
} from "@/app/api/[[...route]]/services/ai/ai-video-workflow/types";
import { usePayments } from "@/features/payments/hooks/usePayments";
import { GetVideoResponseType } from "@/features/storage/sheets/hooks/useGetVideo";
import { firestore } from "@/lib/firebase";
import { useQueryClient } from "@tanstack/react-query";
import { doc, onSnapshot } from "firebase/firestore";
import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Context,
    useEffect,
    useCallback,
    useMemo,
    Dispatch,
    SetStateAction,
    useRef,
} from "react";

interface VideoWorkflowProviderProps {
    children: ReactNode;
    video: GetVideoResponseType["data"];
    initialWorkflowSteps: VideoWorkflowStep[] | null;
    setReloadVideoKey: Dispatch<SetStateAction<number>>;
}

interface VideoWorkflowContextType {
    videoId: string;
    totalSteps: number;
    currentStep: VideoWorkflowStep | null;
    getWorkflowStepByName: (
        stepName: VideoWorkflowStepName
    ) => VideoWorkflowStep | null;
    shouldShowWorkflowStepComponent: (
        stepName: VideoWorkflowStepName
    ) => boolean;
    hasCaptions: boolean;
    workflowRetries: number;
}

const VideoWorkflowContext = createContext<
    VideoWorkflowContextType | undefined
>(undefined);

export const useVideoWorkflow = () => {
    const context = useContext(
        VideoWorkflowContext as Context<VideoWorkflowContextType | undefined>
    );
    if (!context) {
        throw new Error(
            "useVideoWorkflow must be used within a VideoWorkflowContext"
        );
    }
    return context;
};

const getCurrentStep = (workflowSteps?: VideoWorkflowStep[] | null) => {
    return (
        workflowSteps?.find(
            (s) => s.status === "in-progress" || s.status === "failed"
        ) || null
    );
};

export default function VideoWorkflowProvider({
    children,
    video,
    setReloadVideoKey,
    initialWorkflowSteps,
}: VideoWorkflowProviderProps): JSX.Element {
    const [workflowSteps, setWorkflowSteps] = useState<
        VideoWorkflowStep[] | null
    >(initialWorkflowSteps);
    const [workflowRetries, setWorkflowRetries] = useState(0);

    const workflowStepsRef = useRef<VideoWorkflowStep[] | null>(
        initialWorkflowSteps
    );
    const completedStepsRef = useRef<VideoWorkflowStepName[]>([]);

    const currentStep = useMemo(
        () => getCurrentStep(workflowSteps),
        [workflowSteps]
    );
    const videoId = useMemo(() => video.id, [video.id]);
    const hasCaptions = useMemo(
        () =>
            !!(
                workflowSteps?.some((step) => step.name === "captions") ||
                video.captionsId
            ),
        [video.captionsId, workflowSteps]
    );

    const setPaymentModalOpen = usePayments(
        (state) => state.setPaymentModalOpen
    );
    const queryClient = useQueryClient();

    // dont open trigger modal, just show error message its enough
    // useEffect(() => {
    //     if (currentStep?.error) {
    //         if (currentStep.error.openPaymentsModal) {
    //             setPaymentModalOpen(true, "PaymentWall");
    //         }
    //     }
    // }, [currentStep, setPaymentModalOpen]);

    // handle completed steps
    useEffect(() => {
        const oldWorkflowSteps = workflowStepsRef.current;
        const newWorkflowSteps = workflowSteps;

        const justCompletedStepName = newWorkflowSteps?.find(
            (newStep) =>
                newStep.status === "completed" &&
                oldWorkflowSteps?.find(
                    (oldStep) => oldStep.name === newStep.name
                )?.status !== "completed"
        )?.name;

        if (justCompletedStepName) {
            if (!completedStepsRef.current.includes(justCompletedStepName)) {
                console.log("step completed:", justCompletedStepName);

                completedStepsRef.current = [
                    ...completedStepsRef.current,
                    justCompletedStepName,
                ];

                switch (justCompletedStepName) {
                    case "processing":
                        queryClient.invalidateQueries({
                            queryKey: ["get-video", videoId],
                        });
                        setReloadVideoKey(Date.now());
                        break;
                    case "captions":
                        queryClient.invalidateQueries({
                            queryKey: ["get-video", videoId],
                        });
                        setReloadVideoKey(Date.now());
                        // toast.success("הכתוביות נוצרו בהצלחה!", {
                        //     keyName: "toast.success.generate-video-captions",
                        // });
                        break;
                    case "chapters":
                        queryClient.invalidateQueries({
                            queryKey: ["get-video", videoId],
                        });
                        setReloadVideoKey(Date.now());
                        // toast.success("הפרקים נוצרו בהצלחה!", {
                        //     keyName: "toast.success.generate-video-chapters",
                        // });
                        break;
                    case "interactions":
                        queryClient.invalidateQueries({
                            queryKey: ["get-interactions", videoId],
                        });
                        setReloadVideoKey(Date.now());
                        // toast.success("השאלות נוצרו בהצלחה!", {
                        //     keyName: "toast.success.generate-video-interactions",
                        // });
                        break;
                    default:
                        console.log(
                            "unknown justCompletedStepName:",
                            justCompletedStepName
                        );
                        break;
                }
            }
        }

        workflowStepsRef.current = workflowSteps;
    }, [queryClient, setReloadVideoKey, videoId, workflowSteps]);

    useEffect(() => {
        if (!videoId) return;

        console.log("Attempting to set up Firestore listener for:", videoId);
        const unsubscribe = onSnapshot(
            doc(firestore, videoWorkflowCollectionName, videoId),
            (doc) => {
                console.log("Snapshot received for document:", videoId);
                if (doc.exists()) {
                    console.log("Document data:", doc.data());
                    const newWorkflowSteps = doc.data()?.steps as
                        | VideoWorkflowStep[]
                        | undefined;

                    setWorkflowSteps(newWorkflowSteps || null);
                    setWorkflowRetries(doc.data()?.retries || 0);
                } else {
                    console.log("Document does not exist yet.");
                }
            },
            (error) => {
                console.error("Error listening to Firestore document:", error);
            }
        );

        return () => unsubscribe();
    }, [videoId]);

    const getWorkflowStepByName = useCallback(
        (stepName: VideoWorkflowStepName): VideoWorkflowStep | null => {
            return workflowSteps?.find((s) => s.name === stepName) || null;
        },
        [workflowSteps]
    );

    const shouldShowWorkflowStepComponent = (
        stepName: VideoWorkflowStepName
    ) => {
        const step = workflowSteps?.find((st) => st.name === stepName);

        if (!step || step.status !== "completed") {
            return true;
        }
        return false;
    };

    const value: VideoWorkflowContextType = {
        videoId,
        currentStep,
        getWorkflowStepByName,
        totalSteps: workflowSteps?.length || 0,
        shouldShowWorkflowStepComponent,
        hasCaptions,
        workflowRetries,
    };

    return (
        <VideoWorkflowContext.Provider value={value}>
            {children}
        </VideoWorkflowContext.Provider>
    );
}
