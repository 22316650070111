"use client";

import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";
import { LoaderCircle, Plus } from "lucide-react";
import { useInteractions } from "./hooks/interactionsProvider";
import InteractionsList from "./interactionsList/interactionsList";
import VideoWorkflowStep from "../videoWorkflow/videoWorkflowStep";
import { useVideoWorkflow } from "../videoWorkflow/videoWorkflowProvider";
import { client } from "@/lib/hono";
import toast from "react-hot-toast";
import { Dispatch, SetStateAction } from "react";

export default function InteractionsTab({
    setReloadVideoKey,
}: {
    setReloadVideoKey: Dispatch<SetStateAction<number>>;
}) {
    const { createInteraction, saveChanges, changes, loading } =
        useInteractions();
    const { shouldShowWorkflowStepComponent, videoId } = useVideoWorkflow();

    // this is just for easy testing of video interactions generation
    const createInteractions = async () => {
        const res = await client.api.ai["generate-video-interactions"].$post({
            json: {
                videoId,
            },
        });
        const data = await res.json();
        console.log({ data });
        if (!res.ok) toast.error("Failed");
        else {
            toast.success("succeeded");
            setReloadVideoKey(Date.now());
        }
    };

    return (
        <div className="mt-4">
            <div className="flex flex-row gap-2 justify-between items-center">
                <div>
                    <Label className="">
                        <T
                            keyName="video-sheet.interactions-tab.title"
                            defaultValue="אינטראקציות"
                        />
                    </Label>
                    <p className="text-sm text-muted-foreground">
                        <T
                            keyName="video-sheet.interactions-tab.description"
                            defaultValue="הפכו את הסרטון שלכם לאינטראקטיבי עם שאלות ואינטראקציות בתוך הנגן."
                        />
                    </p>
                </div>
                <Button
                    size="sm"
                    variant="outline"
                    className="flex flex-row items-center gap-1 w-fit"
                    onClick={createInteraction}
                >
                    <Plus className="size-4" />
                    <T
                        keyName="video-sheet.interactions-tab.save-btn"
                        defaultValue="אינטראקציה"
                    />
                </Button>
            </div>

            <div className="flex flex-col gap-6">
                <InteractionsList />

                {/* <Button onClick={createInteractions}>
                    Create interactions
                </Button> */}

                {changes && (
                    <Button
                        disabled={loading || !changes}
                        onClick={saveChanges}
                        className="w-fit"
                    >
                        {loading ? (
                            <LoaderCircle className="size-4 animate-spin" />
                        ) : (
                            <T
                                keyName="video-sheet.interactions-tab.save-btn"
                                defaultValue="שמירה"
                            />
                        )}
                    </Button>
                )}

                {shouldShowWorkflowStepComponent("interactions") && (
                    <VideoWorkflowStep
                        stepName="interactions"
                        texts={{
                            title: {
                                keyName:
                                    "video-sheet.create-interactions-with-ai.title",
                                defaultValue: "יצירת שאלות אינטראקטיביות עם AI",
                            },
                            description: {
                                keyName:
                                    "video-sheet.create-interactions-with-ai.description",
                                defaultValue:
                                    "לחצו כאן ובתוך כמה שניות ניצור לכם שאלות אינטראקטיביות על הסרטון",
                            },
                            generateBtn: {
                                keyName:
                                    "video-sheet.generate-interactions-with-ai-btn",
                                defaultValue: "יצירה",
                            },
                        }}
                    />
                )}
            </div>
        </div>
    );
}
