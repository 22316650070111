import { useAuth } from "@/features/auth/hooks/useAuth";
import { usePayments } from "@/features/payments/hooks/usePayments";
import { calcPlanPrice } from "@/features/payments/lib/paymentsConfig";
import { SelectedPlan } from "@/features/payments/types";
import { client } from "@/lib/hono";
import { posthogEvents } from "@/lib/posthog/utils";
import toast from "@/lib/toast";
import { useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { MutableRefObject, useState } from "react";

interface ErrorMessage {
    param: string;
    message: string;
}

interface ErrorResponse {
    messages: ErrorMessage[];
}

interface TransactionResponse {
    success: boolean;
    [key: string]: any;
}

interface Response {
    transaction_response?: TransactionResponse;
    [key: string]: any;
}

export const useCharge = ({
    fieldsRef,
    selectedPlan,
}: {
    fieldsRef: MutableRefObject<any>;
    selectedPlan: SelectedPlan | null;
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [closePaymentModal] = usePayments((state) => [
        state.closePaymentModal,
    ]);
    const [needsInvoice, setNeedsInvoice] = useState(false);

    const { user } = useAuth();
    const router = useRouter();
    const posthog = usePostHog();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const nameForInvoice = needsInvoice
            ? ((formData.get("nameForInvoice") || "") as string)
            : "";
        const idForInvoice = needsInvoice
            ? ((formData.get("idForInvoice") || "") as string)
            : "";

        console.log({ nameForInvoice, idForInvoice });

        chargeCcData({ nameForInvoice, idForInvoice });
    };

    const chargeCcData = async ({
        nameForInvoice,
        idForInvoice,
    }: {
        nameForInvoice: string;
        idForInvoice: string;
    }) => {
        if (!selectedPlan) {
            setErrorMessage("לא נבחר מנוי.");
            return;
        }

        if (fieldsRef.current) {
            setLoading(true);
            const planPrice = parseFloat(calcPlanPrice(selectedPlan.price));
            const company =
                nameForInvoice || idForInvoice
                    ? `${nameForInvoice}${
                          nameForInvoice && idForInvoice ? " - " : ""
                      }${idForInvoice}`
                    : "";

            fieldsRef.current.charge(
                {
                    terminal_name:
                        process.env.NEXT_PUBLIC_TRANZILA_TERMINAL_NAME,
                    amount: planPrice.toString(),
                    tokenize: true,
                    notify_url: `${process.env.NEXT_PUBLIC_SERVER_URL}/webhooks/payments/tranzilla`,
                    response_language: "hebrew",
                    email: user?.email,
                    contact: user?.name,
                    ...(company && {
                        company,
                    }),
                },
                async function (
                    err: ErrorResponse | null,
                    response: Response | null
                ) {
                    if (err) {
                        await handleErrors(err);
                    }
                    if (response) {
                        await parseResponse(
                            response,
                            planPrice,
                            company,
                            nameForInvoice,
                            idForInvoice
                        );
                    }
                    setLoading(false);
                }
            );
        }
    };

    const handleErrors = async (error: ErrorResponse) => {
        error.messages.forEach(function (message: ErrorMessage) {
            const errorElement = document.getElementById(
                "errors_for_" + message.param
            );
            if (errorElement) {
                errorElement.textContent = message.message;
            }
        });
    };

    const parseResponse = async (
        response: Response,
        planPrice: number,
        company: string,
        nameForInvoice: string,
        idForInvoice: string
    ) => {
        // Handle the response from Tranzila
        if (
            response.transaction_response &&
            response.transaction_response.success
        ) {
            // Payment succeeded
            console.log("Vaildation successful:", response);

            const res = await client.api.payments["create-sto"].$post({
                json: {
                    frequency: selectedPlan!.frequency,
                    planId: selectedPlan!.id,
                    card: {
                        token: response.transaction_response.token,
                        expiry_month:
                            response.transaction_response.expiry_month,
                        expiry_year: response.transaction_response.expiry_year,
                    },
                    clientPlanPrice: planPrice,
                    company,
                    nameForInvoice,
                    idForInvoice,
                },
            });

            if (!res.ok) {
                // show error
                setErrorMessage(
                    "קרתה שגיאה לא צפויה. צרו קשר עם שירות הלקוחות שלנו."
                );
            } else {
                posthog.capture(posthogEvents.purchaseSubscription, {
                    price: planPrice,
                    frequency: selectedPlan!.frequency,
                    plan_name: selectedPlan!.name,
                });
                toast.success("ההצטרפות הושלמה בהצלחה!", {
                    keyName: "toast.success.payment-successful",
                });
                router.refresh();
                closePaymentModal();
            }
        } else {
            // Payment failed
            console.log("Payment failed:", response);
            setErrorMessage(
                response?.transaction_response?.error ||
                    "קרתה שגיאה לא צפויה. צרו קשר עם שירות הלקוחות שלנו."
            );
        }
    };

    return {
        handleSubmit,
        loading,
        errorMessage,
        needsInvoice,
        setNeedsInvoice,
    };
};

const successfulReponse = {
    errors: null,
    transaction_response: {
        success: true,
        error: null,
        processor_response_code: "000",
        transaction_id: "",
        auth_number: "0000000",
        amount: "10",
        currency_code: 1,
        credit_card_last_4_digits: "1815",
        expiry_month: "01",
        expiry_year: "22",
        user_form_data: {
            notify_url: "",
            card_holder_id_number: "",
        },
        card_type: "2",
        card_mask: "458011******1111",
        card_locality: "domestic",
        txn_type: "verify",
        tranmode: "V",
        card_type_name: "Visa",
        token: "asdfa2q323asdf",
        payment_plan: 1,
        total_installments_number: null,
    },
    response_hash: "asdfawefa334f34fa43ff4fa4f",
};
