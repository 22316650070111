import toast from "@/lib/toast";
import { GetVideoResponseType } from "@/features/storage/sheets/hooks/useGetVideo";
import { Dispatch, SetStateAction } from "react";
import { VideoInteraction } from "@dev-xsiter/shared-schema";

export const useInteractionEdit = ({
    video,
    interactions,
    setInteractions,
    setSelectedInteraction,
    setChanges,
}: {
    video: GetVideoResponseType["data"];
    interactions: VideoInteraction[];
    setInteractions: Dispatch<SetStateAction<VideoInteraction[]>>;
    setSelectedInteraction: Dispatch<SetStateAction<VideoInteraction | null>>;
    setChanges: Dispatch<SetStateAction<boolean>>;
}) => {
    const updateInteraction = (
        id: string,
        fieldsToUpdate: Partial<VideoInteraction>
    ) => {
        if (
            fieldsToUpdate.timestamp &&
            (fieldsToUpdate.timestamp <= 0 ||
                (video.length && fieldsToUpdate.timestamp >= video.length))
        ) {
            toast.error("הזמן חייב להיות בתוך גבולות הסרטון", {
                keyName: "toast.error.interaction-timestamp-out-of-bounds",
            });
            return;
        }
        if (
            fieldsToUpdate.timestamp &&
            !!interactions.find(
                (i) => i.id !== id && i.timestamp === fieldsToUpdate.timestamp
            )
        ) {
            toast.error("הזמן שבחרת תפוס על ידי אינטראקציה אחרת", {
                keyName: "toast.error.interaction-timestamp-is-taken",
            });
            return;
        }
        if (
            (fieldsToUpdate.type === "close" ||
                fieldsToUpdate.type === "open") &&
            fieldsToUpdate?.content?.rewindTimestamp &&
            (fieldsToUpdate.content.rewindTimestamp < 0 ||
                (video.length &&
                    fieldsToUpdate.content.rewindTimestamp >= video.length))
        ) {
            toast.error("הזמן חייב להיות בתוך גבולות הסרטון", {
                keyName: "toast.error.interaction-timestamp-out-of-bounds",
            });
            return;
        }

        setInteractions((prev) =>
            prev
                .map((i) =>
                    i.id === id
                        ? ({ ...i, ...fieldsToUpdate } as VideoInteraction)
                        : i
                )
                .sort((a, b) => a.timestamp - b.timestamp)
        );
        setSelectedInteraction((prev) =>
            prev && prev.id === id
                ? ({ ...prev, ...fieldsToUpdate } as VideoInteraction)
                : prev
        );
        setChanges(true);
    };

    return { updateInteraction };
};
