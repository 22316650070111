import { v4 as uuid } from "uuid";
import { Input } from "@/components/ui/input";
import { useInteractions } from "../../../hooks/interactionsProvider";
import {
    FormInteractionContent,
    FormInteractionIconId,
    formInteractionIcons,
} from "@dev-xsiter/shared-schema";
import { PlusCircle, Trash } from "lucide-react";
import { T } from "@tolgee/react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectSeparator,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

const MAX_OPTIONS = 4;

export default function FormFields() {
    const { selectedInteraction, updateInteraction } = useInteractions();

    if (!selectedInteraction || selectedInteraction.type !== "form")
        return <></>;

    const fields = selectedInteraction?.content?.fields || [];

    const updateField = (
        id: string,
        fieldsToUpdate: Partial<FormInteractionContent["fields"][0]>
    ) => {
        updateInteraction(selectedInteraction!.id, {
            content: {
                ...selectedInteraction!.content,
                fields: selectedInteraction!.content.fields.map((o) =>
                    o.id === id ? { ...o, ...fieldsToUpdate } : o
                ),
            },
        });
    };

    const addField = () => {
        updateInteraction(selectedInteraction!.id, {
            content: {
                ...selectedInteraction?.content,
                fields: [
                    ...fields,
                    {
                        id: uuid(),
                        label: "",
                        iconId: "email",
                    } satisfies FormInteractionContent["fields"][0],
                ],
            },
        });
    };

    const deleteField = (id: string) => {
        updateInteraction(selectedInteraction!.id, {
            content: {
                ...selectedInteraction!.content,
                fields: selectedInteraction!.content.fields.filter(
                    (o) => o.id !== id
                ),
            },
        });
    };

    return (
        <div className="flex flex-col gap-2 mb-2">
            <div className="flex flex-row justify-between items-center">
                <Label>
                    <T
                        keyName="interaction-modal.form-fields.label"
                        defaultValue="שדות"
                    />
                </Label>

                {fields.length < MAX_OPTIONS && (
                    <Button
                        variant="link"
                        size="xs"
                        onClick={() => addField()}
                        type="button"
                        className="flex flex-row gap-1.5 items-center text-black h-fit px-0"
                    >
                        <PlusCircle className="size-3.5" />
                        <T
                            keyName="interaction-modal.form-fields.add-btn"
                            defaultValue="הוספת שדה"
                        />
                    </Button>
                )}
            </div>

            <div className="flex flex-col gap-2">
                {fields.map((f, idx) => (
                    <div key={idx} className="flex flex-row items-center">
                        <span className="w-5 text-sm text-muted-foreground">
                            {idx + 1}.
                        </span>
                        <Select
                            value={f.iconId || "none"}
                            onValueChange={(iconId) => {
                                updateField(f.id, {
                                    iconId:
                                        iconId !== "none"
                                            ? (iconId as FormInteractionIconId)
                                            : undefined,
                                });
                            }}
                            dir="rtl"
                        >
                            <SelectTrigger className="me-2 h-8 w-[130px]">
                                <SelectValue className="text-xs" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel className="text-xs">
                                        <T
                                            keyName="interaction-modal.form.select-field-icon.label"
                                            defaultValue="בחרו אייקון"
                                        />
                                    </SelectLabel>
                                    <SelectSeparator />
                                    {formInteractionIcons.map((icon) => {
                                        return (
                                            <SelectItem
                                                key={icon.id}
                                                value={icon.id}
                                            >
                                                <div className="flex flex-row items-center gap-2 text-xs">
                                                    <icon.icon className="size-4" />
                                                    <T
                                                        keyName={icon.keyName}
                                                        defaultValue={
                                                            icon.defaultValue
                                                        }
                                                    />
                                                </div>
                                            </SelectItem>
                                        );
                                    })}
                                    <SelectItem value="none">
                                        <p className="text-xs">
                                            <T
                                                keyName="interaction-modal.form.select-field-icon.none-option"
                                                defaultValue="ללא אייקון"
                                            />
                                        </p>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        <Input
                            className="h-8 text-xs"
                            value={f.label}
                            placeholder="שם השדה"
                            placeholderKeyName="interaction-modal.form.field-input-placeholder"
                            onChange={(e) =>
                                updateField(f.id, {
                                    label: e.target.value,
                                })
                            }
                        />
                        <button onClick={() => deleteField(f.id)} type="button">
                            <Trash className="ms-2 text-destructive size-[16px]" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}
