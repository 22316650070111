import { SrclangType } from "@dev-xsiter/shared-schema";

export const videoWorkflowStepNames: readonly [
    VideoWorkflowStepName,
    ...VideoWorkflowStepName[]
] = ["processing", "captions", "chapters", "interactions"];
export const videoWorkflowStepNamesThatRequireCaptions: readonly [
    string,
    ...string[]
] = ["chapters", "interactions"];

export type VideoWorkflowStepName =
    | "processing"
    | "captions"
    | "chapters"
    | "interactions";

export type VideoWorkflowStepStatus =
    | "queue"
    | "in-progress"
    | "completed"
    | "failed";

export type VideoWorkflowStep = {
    id: string;
    name: VideoWorkflowStepName;
    index: number;
    progress: number;
    status: VideoWorkflowStepStatus;
    error?: {
        keyName: string;
        defaultValue: string;
        openPaymentsModal?: boolean;
    };
    data?: {
        srclang?: SrclangType;
        runpodEndpointId?: string;
    };
};
export const videoWorkflowStepNamesHebrewMapping: Record<
    VideoWorkflowStepName,
    string
> = {
    processing: "עיבוד הסרטון",
    captions: "יצירת כתוביות",
    chapters: "יצירת פרקים",
    interactions: "יצירת שאלות",
};

export const videoWorkflowCollectionName = "ai-video-workflow";
