"use client";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { usePayments } from "../../hooks/usePayments";
import Image from "next/image";
import PaymentWall from "./steps/paymentWall/paymentWall";
import PaymentForm from "./steps/paymentForm/ui/paymentForm";
import ChangePlan from "./steps/changePlan/ui/changePlan";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { posthogEvents } from "@/lib/posthog/utils";

export default function PaymentsModal() {
    const [open, setOpen, currentStep, selectedPlan] = usePayments((state) => [
        state.paymentModalOpen,
        state.setPaymentModalOpen,
        state.currentStep,
        state.selectedPlan,
    ]);
    const posthog = usePostHog();

    useEffect(() => {
        if (open) {
            switch (currentStep) {
                case "PaymentWall":
                    posthog.capture(posthogEvents.paymentTrigger);
                    break;
                case "PaymentForm":
                    posthog.capture(posthogEvents.initiatePayment);
                    break;
            }
        }
    }, [currentStep, open, posthog]);

    return (
        <Dialog
            open={open}
            onOpenChange={(open) => setOpen(open, currentStep, selectedPlan)}
        >
            <DialogContent
                xPosition="top-1 left-1"
                className="p-4 max-w-[800px]"
            >
                <div className="flex w-full h-full flex-col md:flex-row-reverse gap-6">
                    <div className="flex-1">
                        <Image
                            className="h-full w-full object-cover rounded-lg"
                            src="/images/payments/modal-image.png"
                            alt="image"
                            width={500}
                            height={500}
                            quality={100}
                        />
                    </div>
                    {currentStep === "PaymentWall" && <PaymentWall />}
                    {currentStep === "PaymentForm" && <PaymentForm />}
                    {currentStep === "ChangePlan" && <ChangePlan />}
                </div>
            </DialogContent>
        </Dialog>
    );
}
