"use client";

import { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import toast from "@/lib/toast";
import { cn } from "@/lib/utils";
import { CheckCircleIcon } from "lucide-react";
import { T } from "@tolgee/react";
import { client } from "@/lib/hono";

export enum FileType {
    Images = "images",
    PDF = "pdf",
    CSV = "csv",
    Excel = "excel",
    Video = "video",
    Text = "text",
    Word = "word",
}

export const fileAcceptMapping = {
    [FileType.Images]: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
        "image/webp": [".webp"],
        "image/gif": [".gif"],
        "image/bmp": [".bmp"],
        "image/tiff": [".tiff"],
    },
    [FileType.PDF]: {
        "application/pdf": [".pdf"],
    },
    [FileType.CSV]: {
        "text/csv": [".csv"],
    },
    [FileType.Excel]: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
        ],
        "application/vnd.ms-excel": [".xls"],
    },
    [FileType.Video]: {
        "video/mp4": [".mp4"],
        "video/webm": [".webm"],
    },
    [FileType.Text]: {
        "text/plain": [".txt"],
    },
    [FileType.Word]: {
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
    },
};

export type UploadFileType = {
    fileUrl: string;
    fileName: string;
    fileType?: string;
    fileSize?: number;
};

interface UploadFileProps {
    options: {
        allowedFileTypes: FileType[];
        maxFileSize: number; // in MB
        fileUploadPath: string;
        overrideFileName?: string;
        showImagePreview?: boolean;
        title?: { text: string; keyName: string };
        subtitle?: { text: string; keyName: string };
        className?: string;
        listenForInitialFileChanges?: boolean;
        maxImageWidth?: number;
        maxImageHeight?: number;
        purgeCache?: boolean;
    };
    initialFile?: UploadFileType | null;
    onUploadFinished: (uploadedFile: UploadFileType) => void;
}

export default function UploadFile({
    options: {
        allowedFileTypes,
        maxFileSize,
        fileUploadPath,
        overrideFileName,
        showImagePreview,
        title = { text: "העלאת קובץ", keyName: "upload-file.default.title" },
        subtitle = {
            text: "גררו לכאן קובץ, או לחצו כדי לבחור קובץ",
            keyName: "upload-file.default.subtitle",
        },
        className = "h-48 w-full",
        listenForInitialFileChanges,
        maxImageWidth,
        maxImageHeight,
        purgeCache,
    },
    initialFile,
    onUploadFinished,
}: UploadFileProps) {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [sizeLimitError, setShowSizeLimitError] = useState(false);
    const [currentFile, setCurrentFile] = useState<
        UploadFileType | null | undefined
    >(initialFile);

    useEffect(() => {
        if (listenForInitialFileChanges) {
            setCurrentFile(initialFile);
        }
    }, [initialFile, listenForInitialFileChanges]);

    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
            toast.error("סוג הקובץ לא נתמך.", {
                keyName: "toast.error.upload-file.type-not-supported",
            });
        }
    }, []);

    const onDropAccepted = useCallback(
        async (acceptedFiles: File[]) => {
            const downscaleImageIfNeeded = async (
                file: File,
                maxWidth: number,
                maxHeight: number
            ): Promise<File> => {
                console.log("downscaleImageIfNeeded was called");

                const img = new Image();
                const reader = new FileReader();

                return new Promise<File>((resolve, reject) => {
                    reader.onload = (e) => {
                        img.src = e.target?.result as string;
                    };

                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        let width = img.width;
                        let height = img.height;

                        if (img.width > maxWidth || img.height > maxHeight) {
                            console.log("downscaling image");

                            const aspectRatio = img.width / img.height;

                            if (img.width > img.height) {
                                width = maxWidth;
                                height = width / aspectRatio;
                            } else {
                                height = maxHeight;
                                width = height * aspectRatio;
                            }

                            canvas.width = width;
                            canvas.height = height;

                            const ctx = canvas.getContext("2d");
                            if (ctx) {
                                ctx.drawImage(img, 0, 0, width, height);
                            }

                            canvas.toBlob((blob) => {
                                if (blob) {
                                    const resizedFile = new File(
                                        [blob],
                                        file.name,
                                        {
                                            type: file.type,
                                            lastModified: Date.now(),
                                        }
                                    );
                                    resolve(resizedFile);
                                } else {
                                    resolve(file); // Return original file if no downscale needed
                                }
                            }, file.type);
                        } else {
                            console.log("no need to downscale");

                            resolve(file); // No need to downscale
                        }
                    };

                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            };

            if (isLoading) {
                toast.error("יש לחכות שההעלאה הנוכחית תסתיים.", {
                    keyName: "toast.error.upload-file.already-uploading",
                });
                return;
            }

            if (acceptedFiles.length > 1) {
                toast.error("ניתן להעלות קובץ אחד בלבד בכל פעם.", {
                    keyName: "toast.error.upload-file.multiple-files-attempt",
                });
                return;
            }

            if (!fileUploadPath) {
                toast.error("אירעה שגיאה", { keyName: "toast.error.default" });
            }

            const fileSizeInMB = acceptedFiles[0].size / 1024 / 1024; // Size in MB
            if (fileSizeInMB > maxFileSize) {
                setShowSizeLimitError(true);
                toast.error("הקובץ שהועלה כבד מידי", {
                    keyName: "toast.error.file-upload.exceeded-max-file-size",
                });
                return;
            } else {
                setShowSizeLimitError(false);
            }

            setIsLoading(true);

            const file = acceptedFiles[0];
            const localUrl = URL.createObjectURL(file);
            setCurrentFile({ fileName: file.name, fileUrl: localUrl });

            const res = await client.api.storage["file-upload"].$post({
                json: {
                    fileName: overrideFileName || file.name,
                    contentType: file.type,
                    fileUploadPath,
                    purgeCache,
                },
            });

            if (!res.ok) {
                toast.error("קרתה שגיאה לא צפויה", {
                    keyName: "toast.error.default",
                });
                setIsLoading(false);
                return;
            }

            let processedFile = file;
            if (maxImageWidth && maxImageHeight) {
                processedFile = await downscaleImageIfNeeded(
                    file,
                    maxImageWidth,
                    maxImageHeight
                );
            }

            const { signedUrl, publicUrl } = await res.json();

            try {
                const xhr = new XMLHttpRequest();
                xhr.open("PUT", signedUrl, true);
                xhr.setRequestHeader("Content-Type", processedFile.type);

                // Monitor upload progress
                xhr.upload.addEventListener("progress", (event) => {
                    if (event.lengthComputable) {
                        const progress = (event.loaded / event.total) * 100;
                        setUploadProgress(progress);
                    }
                });

                // Handle upload error
                xhr.addEventListener("error", (error) => {
                    console.error(error);
                    toast.error("אירעה שגיאה", {
                        keyName: "toast.error.default",
                    });
                    setIsLoading(false);
                });

                // Handle upload completion
                xhr.addEventListener("load", () => {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        onUploadFinished({
                            fileName: file.name,
                            fileType: file.type,
                            fileSize: file.size,
                            fileUrl: publicUrl,
                        });

                        toast.success("הקובץ הועלה בהצלחה!", {
                            keyName: "toast.success.upload-file",
                        });
                        setUploadProgress(0);
                    } else {
                        // Error status code from the server
                        console.error("Upload failed with status:", xhr.status);
                        toast.error("אירעה שגיאה בהעלאה", {
                            keyName: "toast.error.default",
                        });
                    }
                    setIsLoading(false);
                });

                // Start the actual upload
                xhr.send(processedFile);
            } catch (err) {
                console.error(err);
                toast.error("אירעה שגיאה", {
                    keyName: "toast.error.default",
                });
                setIsLoading(false);
            }
        },
        [
            fileUploadPath,
            isLoading,
            maxFileSize,
            maxImageHeight,
            maxImageWidth,
            onUploadFinished,
            overrideFileName,
            purgeCache,
        ]
    );

    const accept = allowedFileTypes.reduce((acc, type) => {
        return { ...acc, ...fileAcceptMapping[type] };
    }, {});

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropAccepted,
        onDropRejected,
        accept,
        multiple: false,
    });

    return (
        <div
            {...getRootProps()}
            className={cn(
                "flex flex-col items-center justify-center p-4 border-2 border-dashed rounded-md cursor-pointer text-center transition-colors",
                isDragActive
                    ? "bg-slate-100 border-gray-500"
                    : "border-gray-400 hover:bg-slate-100 hover:border-gray-500",
                className && className
            )}
        >
            <input {...getInputProps()} />
            {currentFile ? (
                <div className="flex flex-col items-center">
                    {!isLoading && !showImagePreview && (
                        <CheckCircleIcon className="h-6 w-6 text-green-500" />
                    )}

                    {showImagePreview && currentFile.fileUrl && (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img
                            src={currentFile.fileUrl}
                            alt="Preview"
                            className={cn(
                                "max-h-24 max-w-full mb-1 rounded-md shadow-sm",
                                isDragActive ? "opacity-70" : "opacity-100"
                            )}
                        />
                    )}

                    <p className="font-semibold text-sm mt-1">הקובץ הנבחר:</p>
                    <p className="text-xs text-gray-600 mt-1">
                        {currentFile.fileName}
                    </p>
                </div>
            ) : (
                <div>
                    <p className="font-semibold text-sm">
                        <T keyName={title.keyName} defaultValue={title.text} />
                    </p>
                    <p className="text-xs mt-0.5 text-gray-600">
                        <T
                            keyName={subtitle.keyName}
                            defaultValue={subtitle.text}
                        />
                    </p>
                </div>
            )}
            {isLoading && (
                <div className="mt-2 w-full bg-gray-200 rounded-full h-1.5">
                    <div
                        className="bg-primary h-1.5 rounded-full transition-width duration-150 ease-linear"
                        style={{ width: `${uploadProgress}%` }}
                    ></div>
                </div>
            )}
            {sizeLimitError && (
                <div>
                    <p className="text-destructive text-sm font-semibold mt-1">
                        <T
                            keyName="upload-file.max-file-size-exceeded-error.text"
                            defaultValue="הגודל המקסימלי הוא {limit}MB"
                            params={{ limit: maxFileSize }}
                        />
                    </p>
                </div>
            )}
        </div>
    );
}
