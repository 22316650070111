/**
 * 1 tokens = 0.01$
 */

import { SelectUsageLimits } from "@dev-xsiter/shared-schema";
import { SelectedPlanPrice } from "../types";

export const paymentsConfig = {
    tokens: {
        presentation: [1, 2, 3, 4], // for the different length options
        syllabus: [1, 2, 3, 4], // for the different length options
        videoCaptions: 1 / 3, // tokens per minute
        videoChapters: 0.1, // tokens per minute
        videoInteractions: 0.1, // tokens per minute
    },
};

export const vatPercentage = 18;
export const yearlyDiscountPercentage = 30;
export const calcPlanPrice = (price: SelectedPlanPrice): string => {
    const discountPercentage = price.discountPercentage - price.vatPercentage;
    return (
        Math.round(
            ((price.planPrice * (100 - discountPercentage)) / 100) * 100
        ) / 100
    ).toFixed(2);
};
export const trialPeriod = 30; // days

export const defaultUsageLimits: Omit<
    SelectUsageLimits,
    "id" | "userId" | "createdAt" | "updatedAt"
> = {
    tokenLimit: 20,
    storageLimitInGB: 1,
    trafficLimitInGB: 1,
    videoEditorLimitInMinutes: 3,
    allowDownloadVideos: false,
};

export const freePlanName = "חינם";
