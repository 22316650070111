"use client";

import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { T } from "@tolgee/react";
import { Pencil } from "lucide-react";

export default function EditVideoButton({ videoId }: { videoId: string }) {
    return (
        <a
            href={`/edit-video/${videoId}`}
            className={cn(buttonVariants({ size: "sm", variant: "secondary" }))}
        >
            <Pencil className="size-3.5 me-2" />
            <T keyName="video-sheet.header.edit-btn" defaultValue="עריכה" />
        </a>
    );
}
