"use client";

import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { SrclangType } from "@dev-xsiter/shared-schema";
import { useQueryClient } from "@tanstack/react-query";
import { T } from "@tolgee/react";
import { LoaderCircle } from "lucide-react";
import { useState } from "react";

export default function EditCaptions({
    captionsId,
    srt,
    srclang,
    setReloadVideoKey,
}: {
    captionsId: string;
    srt: string;
    srclang: SrclangType;
    setReloadVideoKey: (key: number) => void;
}) {
    const [changes, setChanges] = useState(false);
    const [loading, setLoading] = useState(false);

    const queryClient = useQueryClient();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const srt = e.target.srt.value;

        if (!srt) {
            toast.error("אירעה שגיאה", { keyName: "toast.error.default" });
            return;
        }

        setLoading(true);

        const res = await client.api.storage.captions[
            ":captionsId"
        ].update.$post({
            param: { captionsId },
            json: {
                srt,
            },
        });

        const resJson = await res.json();
        console.log({ resJson });

        if (!res.ok) {
            toast.error("אירעה שגיאה בניסיון עדכון כתוביות", {
                keyName: "toast.error.default",
            });
            setLoading(false);
            return;
        }

        queryClient.invalidateQueries({
            queryKey: ["get-captions", captionsId],
        });

        setReloadVideoKey(Date.now());
        toast.success("השינויים נשמרו בהצלחה!", {
            keyName: "toast.success.save-changes",
        });
        setLoading(false);
        setChanges(false);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4 flex w-full flex-col">
            <Label htmlFor="srt">
                <T
                    keyName="video-sheet.captions-tab.title"
                    defaultValue="כתוביות"
                />
            </Label>
            <Textarea
                id="srt"
                onChange={() => {
                    setChanges(true);
                }}
                defaultValue={srt}
                dir={srclang === "he" ? "rtl" : "ltr"}
                className="h-[300px] w-full mt-2 mb-4"
            />
            <div className="flex w-full justify-end">
                <Button
                    disabled={loading || !changes}
                    type="submit"
                    className="w-fit"
                >
                    {loading ? (
                        <LoaderCircle className="size-4 animate-spin" />
                    ) : (
                        <T
                            keyName="video-sheet.captions-tab.save-changes"
                            defaultValue="שמירת שינויים"
                        />
                    )}
                </Button>
            </div>
        </form>
    );
}
