"use client";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { T } from "@tolgee/react";
import { MinusCircle, PlusCircle } from "lucide-react";
import { useEffect, useState } from "react";

interface TimestampInputProps {
    valueInSeconds: number;
    onValueChange: (value: number) => void;
    side?: "bottom" | "top" | "right" | "left" | undefined;
    align?: "center" | "end" | "start" | undefined;
    disabled?: boolean;
    triggerClassName?: string;
}

type InputId = "hours" | "minutes" | "seconds";

export default function TimestampInput({
    valueInSeconds,
    onValueChange,
    side,
    align,
    disabled,
    triggerClassName,
}: TimestampInputProps) {
    const secondsToHMS = (secs: number) => {
        const h = Math.floor(secs / 3600);
        const m = Math.floor((secs % 3600) / 60);
        const s = secs % 60;
        return { h, m, s };
    };

    const {
        h: initialH,
        m: initialM,
        s: initialS,
    } = secondsToHMS(valueInSeconds);

    const [hours, setHours] = useState<string>(pad(initialH));
    const [minutes, setMinutes] = useState<string>(pad(initialM));
    const [seconds, setSeconds] = useState<string>(pad(initialS));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const { h, m, s } = secondsToHMS(valueInSeconds);
        setHours(pad(h));
        setMinutes(pad(m));
        setSeconds(pad(s));
    }, [valueInSeconds]);

    const clamp = (val: number, min: number, max?: number) => {
        if (isNaN(val)) return min;
        if (max !== undefined) return Math.min(Math.max(val, min), max);
        return Math.max(val, min);
    };

    function pad(num: number) {
        return num.toString().padStart(2, "0");
    }

    const updateValue = (newH: string, newM: string, newS: string) => {
        const parsedH = clamp(parseInt(newH, 10) || 0, 0, 9);
        const parsedM = clamp(parseInt(newM, 10) || 0, 0, 59);
        const parsedS = clamp(parseInt(newS, 10) || 0, 0, 59);
        onValueChange(parsedH * 3600 + parsedM * 60 + parsedS);
    };

    const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value.replace(/\D/g, "");
        const clampedVal = pad(clamp(parseInt(val, 10) || 0, 0, 9));
        setHours(clampedVal);
        updateValue(clampedVal, minutes, seconds);
    };

    const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value.replace(/\D/g, "");
        const clampedVal = pad(clamp(parseInt(val, 10) || 0, 0, 59));
        setMinutes(clampedVal);
        updateValue(hours, clampedVal, seconds);
    };

    const handleSecondsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value.replace(/\D/g, "");
        const clampedVal = pad(clamp(parseInt(val, 10) || 0, 0, 59));
        setSeconds(clampedVal);
        updateValue(hours, minutes, clampedVal);
    };

    const increment = (field: InputId) => {
        const currentVal = parseInt(
            field === "hours" ? hours : field === "minutes" ? minutes : seconds,
            10
        );

        const maxLimit = field === "hours" ? 9 : 59; // Hours max 9, Minutes/Seconds max 59
        const newVal = clamp(currentVal + 1, 0, maxLimit);
        const paddedVal = pad(newVal);

        if (field === "hours") {
            setHours(paddedVal);
            updateValue(paddedVal, minutes, seconds);
        } else if (field === "minutes") {
            setMinutes(paddedVal);
            updateValue(hours, paddedVal, seconds);
        } else {
            setSeconds(paddedVal);
            updateValue(hours, minutes, paddedVal);
        }
    };

    const decrement = (
        field: "hours" | "minutes" | "seconds",
        min: number = 0
    ) => {
        const currentVal = parseInt(
            field === "hours" ? hours : field === "minutes" ? minutes : seconds,
            10
        );
        const newVal = clamp(currentVal - 1, min);
        const paddedVal = pad(newVal);

        if (field === "hours") {
            setHours(paddedVal);
            updateValue(paddedVal, minutes, seconds);
        } else if (field === "minutes") {
            setMinutes(paddedVal);
            updateValue(hours, paddedVal, seconds);
        } else {
            setSeconds(paddedVal);
            updateValue(hours, minutes, paddedVal);
        }
    };

    const inputs = [
        {
            label: {
                keyName: "timestamp-input.popover.seconds",
                defaultValue: "שניות",
            },
            id: "seconds" as InputId,
            value: seconds,
            onChange: handleSecondsChange,
        },
        {
            label: {
                keyName: "timestamp-input.popover.minutes",
                defaultValue: "דקות",
            },
            id: "minutes" as InputId,
            value: minutes,
            onChange: handleMinutesChange,
        },
        {
            label: {
                keyName: "timestamp-input.popover.hours",
                defaultValue: "שעות",
            },
            id: "hours" as InputId,
            value: hours,
            onChange: handleHoursChange,
        },
    ];

    return (
        <Popover
            open={open}
            onOpenChange={(open) => {
                if (disabled) {
                    setOpen(false);
                } else {
                    setOpen(open);
                }
            }}
        >
            <PopoverTrigger asChild>
                <div
                    className={cn(
                        "flex h-9 cursor-pointer w-full items-center rounded-md border border-input bg-background px-2.5 py-2 text-sm ring-offset-background",
                        disabled ? "cursor-default" : "cursor-pointer",
                        triggerClassName
                    )}
                >
                    <p>
                        {hours}:{minutes}:{seconds}
                    </p>
                </div>
            </PopoverTrigger>
            <PopoverContent
                side={side}
                align={align}
                className="flex flex-col gap-2 w-fit p-4"
            >
                {inputs.map((input) => (
                    <div
                        key={input.id}
                        className="flex items-center justify-between gap-8"
                    >
                        <Label htmlFor={input.id}>
                            <T
                                keyName={input.label.keyName}
                                defaultValue={input.label.defaultValue}
                            />
                            :
                        </Label>
                        <div className="flex items-center gap-x-1">
                            <MinusCircle
                                className="size-[18px] text-gray-400 hover:text-gray-600 transition cursor-pointer"
                                onClick={() => decrement(input.id)}
                            />
                            <Input
                                dir="ltr"
                                id={input.id}
                                type="text"
                                value={input.value}
                                onChange={input.onChange}
                                className="h-8 w-12 text-center"
                            />
                            <PlusCircle
                                className="size-[18px] text-gray-400 hover:text-gray-600 transition cursor-pointer"
                                onClick={() => increment(input.id)}
                            />
                        </div>
                    </div>
                ))}
            </PopoverContent>
        </Popover>
    );
}
