"use client";

import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";
import { useInteractions } from "../../../hooks/interactionsProvider";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import TimestampInput from "@/components/ui/timestamp-input";
import { VideoInteractionType } from "@dev-xsiter/shared-schema";

const attemptsOptions = [
    {
        value: 1,
        keyName: "interaction-modal.settings.attempts-limit.1",
        defaultValue: "1",
    },
    {
        value: 2,
        keyName: "interaction-modal.settings.attempts-limit.2",
        defaultValue: "2",
    },
    {
        value: 3,
        keyName: "interaction-modal.settings.attempts-limit.3",
        defaultValue: "3",
    },
    {
        value: 4,
        keyName: "interaction-modal.settings.attempts-limit.4",
        defaultValue: "4",
    },
    {
        value: 5,
        keyName: "interaction-modal.settings.attempts-limit.5",
        defaultValue: "5",
    },
    {
        value: 10,
        keyName: "interaction-modal.settings.attempts-limit.10",
        defaultValue: "10",
    },
];

export default function SettingsOptions() {
    const { selectedInteraction, updateInteraction } = useInteractions();

    const showAttemptsField =
        selectedInteraction?.type === "close" ||
        selectedInteraction?.type === "open";

    const showRewindField =
        selectedInteraction?.type === "close" ||
        selectedInteraction?.type === "open";

    const showAllowSkippingField =
        selectedInteraction?.type === "close" ||
        selectedInteraction?.type === "open" ||
        selectedInteraction?.type === "form" ||
        selectedInteraction?.type === "rating";

    return (
        <div className="flex flex-col gap-6">
            {showAttemptsField && (
                <div className="flex flex-row w-full gap-4">
                    <div className="flex w-full flex-col gap-2">
                        <Label>
                            <T
                                keyName="interaction-modal.settings.select-attempt-limit.label"
                                defaultValue="מספר נסיונות מענה"
                            />
                        </Label>
                        <Select
                            dir="rtl"
                            value={selectedInteraction?.content.attemptLimit?.toString()}
                            onValueChange={(value) => {
                                updateInteraction(selectedInteraction!.id, {
                                    content: {
                                        ...selectedInteraction?.content,
                                        attemptLimit: Number(value),
                                    },
                                });
                            }}
                        >
                            <SelectTrigger className="h-9">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                {attemptsOptions.map((o) => (
                                    <SelectItem
                                        key={o.value}
                                        value={o.value.toString()}
                                    >
                                        <T
                                            keyName={o.keyName}
                                            defaultValue={o.defaultValue}
                                        />
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            )}

            {showAllowSkippingField && (
                <div className="flex items-center gap-x-2 -mb-2">
                    <Checkbox
                        checked={!!selectedInteraction?.content.allowSkipping}
                        onCheckedChange={(checked) =>
                            updateInteraction(selectedInteraction!.id, {
                                content: {
                                    ...selectedInteraction?.content,
                                    allowSkipping: !!checked,
                                },
                            })
                        }
                    />
                    <Label className="">
                        <T
                            keyName="interaction-modal.settings.allowSkipping"
                            defaultValue="הצגת אפשרות דילוג"
                        />
                    </Label>
                </div>
            )}

            {showRewindField && (
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-x-2">
                        <Checkbox
                            checked={
                                typeof selectedInteraction?.content
                                    .rewindTimestamp === "number"
                            }
                            onCheckedChange={(checked) =>
                                updateInteraction(selectedInteraction!.id, {
                                    content: {
                                        ...selectedInteraction?.content,
                                        rewindTimestamp: checked ? 0 : null,
                                    },
                                })
                            }
                        />
                        <Label className="">
                            <T
                                keyName="interaction-modal.settings.repeatUntilCorrect"
                                defaultValue="החזרת הצופה אחורה לאחר כשלון"
                            />
                        </Label>
                    </div>

                    {typeof selectedInteraction?.content.rewindTimestamp ===
                        "number" && (
                        <TimestampInput
                            valueInSeconds={
                                selectedInteraction?.content.rewindTimestamp
                            }
                            onValueChange={(value) =>
                                updateInteraction(selectedInteraction!.id, {
                                    content: {
                                        ...selectedInteraction?.content,
                                        rewindTimestamp: value,
                                    },
                                })
                            }
                            triggerClassName="w-1/2"
                        />
                    )}
                </div>
            )}
        </div>
    );
}
