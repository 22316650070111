import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";

export default function TranslationsTab() {
    return (
        <div className="mt-4">
            <div className="flex flex-row gap-2 justify-between items-center">
                <div>
                    <Label className="">
                        <T
                            keyName="video-sheet.translations-tab.title"
                            defaultValue="תרגומים"
                        />
                    </Label>
                    <p className="text-sm text-muted-foreground">
                        <T
                            keyName="video-sheet.translations-tab.description"
                            defaultValue="תרגמו את הסרטון לשפות נוספות עם סנכרון שפתיים."
                        />
                    </p>
                </div>
            </div>

            <div className="flex flex-col my-8 text-xl">
                <p className="font-semibold text-center">בקרוב...</p>
            </div>
        </div>
    );
}
