"use client";

import {
    DD_PRESENTATION_COUNT,
    DD_SYLLABUS_COUNT,
    DD_VIDEO_COUNT,
} from "@/lib/initDatadog";
import { datadogRum } from "@datadog/browser-rum";
import { Session, User } from "next-auth";
import { Context, createContext, useContext, useEffect } from "react";
import { usePathname, useRouter } from "next/navigation";
import { MOBILE_NOT_SUPPORTED_ROUTES, PUBLIC_ROUTES } from "../utils";
import { usePostHog } from "posthog-js/react";

export interface AuthContextValue {
    user: User | null;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(
        AuthContext as Context<AuthContextValue | undefined>
    );
    if (!context) {
        throw new Error("useAuth must be used within a AuthContext");
    }
    return context;
};

export interface AuthProviderProps {
    children: React.ReactNode;
    session: Session | null;
    isMobile: boolean;
}

export const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
    children,
    session,
    isMobile,
}) => {
    const pathname = usePathname();
    const router = useRouter();
    const posthog = usePostHog();

    useEffect(() => {
        if (
            isMobile &&
            MOBILE_NOT_SUPPORTED_ROUTES.some((route) =>
                pathname.includes(route)
            )
        ) {
            router.replace("/mobile-not-supported");
        }
    }, [isMobile, pathname, session?.user, router]);

    useEffect(() => {
        if (session?.user) {
            const user = session.user;

            datadogRum.setUser({
                id: user.id,
                name: user.name || undefined,
                email: user.email || undefined,
                [DD_PRESENTATION_COUNT]: 0,
                [DD_SYLLABUS_COUNT]: 0,
                [DD_VIDEO_COUNT]: 0,
            });

            posthog?.identify(
                user.id,
                {
                    email: user.email,
                    name: user.name || "",
                },
                {
                    created_at: user.createdAt,
                }
            );
        }
    }, [posthog, session]);

    return (
        <AuthContext.Provider value={{ user: session?.user || null }}>
            {children}
        </AuthContext.Provider>
    );
};
