"use client";

import { Button } from "@/components/ui/button";
import { T } from "@tolgee/react";
import { Download, LoaderCircle } from "lucide-react";
import { useState } from "react";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { usePayments } from "@/features/payments/hooks/usePayments";

export default function DownloadVideoButton({
    videoId,
    originalFileFormat,
}: {
    videoId: string;
    originalFileFormat: string | null;
}) {
    const [loading, setLoading] = useState(false);
    const setPaymentModalOpen = usePayments(
        (state) => state.setPaymentModalOpen
    );

    const downloadVideo = async () => {
        setLoading(true);

        const res = await client.api.storage.video[":videoId"].download.$get({
            param: { videoId },
        });

        if (!res.ok) {
            setLoading(false);

            if (res.status === 402) {
                setPaymentModalOpen(true, "PaymentWall");
            } else {
                toast.error("אירעה שגיאה", { keyName: "toast.error.default" });
            }

            return;
        }

        const data = await res.json();
        const { downloadUrl } = data.data;

        try {
            // Open the download in a new tab to bypass CORS restrictions
            window.open(downloadUrl, "_blank", "noopener,noreferrer");

            toast.success("ההורדה החלה!", {
                keyName: "toast.success.download-started",
            });
        } catch (error) {
            console.error("Download error:", error);
            toast.error("שגיאה בהורדה", {
                keyName: "toast.error.default",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            onClick={downloadVideo}
            disabled={loading}
            variant="secondary"
            size="sm"
        >
            {loading ? (
                <div className="w-[58px] flex justify-center items-center">
                    <LoaderCircle className="size-4 animate-spin" />
                </div>
            ) : (
                <>
                    <Download className="size-3.5 me-2" />
                    <T
                        keyName="video-sheet.header.download-btn"
                        defaultValue="הורדה"
                    />
                </>
            )}
        </Button>
    );
}
