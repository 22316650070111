"use client";

import { useInteractions } from "../../../hooks/interactionsProvider";
import StepHeader from "../../ui/stepHeader";
import ContinueButton from "../../ui/continueButton";
import SettingsOptions from "./settings";

export default function SettingsStep() {
    const { selectedInteraction } = useInteractions();

    if (!selectedInteraction) return <></>;

    return (
        <div className="flex flex-col gap-6 w-full">
            <StepHeader
                title={{
                    keyName: "interaction-modal.settings.header.title",
                    defaultValue: "הגדרות",
                }}
                description={{
                    keyName: "interaction-modal.settings.header.description",
                    defaultValue: "בחרו את הגדרות האינטראקציה",
                }}
            />

            <SettingsOptions />

            <ContinueButton />
        </div>
    );
}
