"use client";

import { Label } from "@/components/ui/label";
import { GetVideoResponseType } from "../../hooks/useGetVideo";
import { T } from "@tolgee/react";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useState } from "react";
import {
    Copy,
    Check,
    Globe,
    Lock,
    LucideIcon,
    AlertTriangle,
} from "lucide-react";
import { getVideoEmbedUrl } from "@/lib/utils";
import toast from "@/lib/toast";
import { client } from "@/lib/hono";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { useQueryClient } from "@tanstack/react-query";
import { VideoPrivacyType } from "@dev-xsiter/shared-schema";

type EmbedOption = "captions" | "autoPlay" | "muted" | "disableSeeking";

const privacyOptions: {
    value: VideoPrivacyType;
    keyName: string;
    defaultValue: string;
    icon: LucideIcon;
}[] = [
    {
        value: "public",
        keyName: "storage.video-privacy-settings.public",
        defaultValue: "פתוח",
        icon: Globe,
    },
    {
        value: "private",
        keyName: "storage.video-privacy-settings.private",
        defaultValue: "פרטי",
        icon: Lock,
    },
];

export default function VideoSheetAside({
    video,
}: {
    video: GetVideoResponseType["data"];
}) {
    const [embedOptions, setEmbedOptions] = useState<
        {
            id: EmbedOption;
            keyName: string;
            defaultValue: string;
            selected: boolean;
        }[]
    >([
        {
            id: "autoPlay",
            keyName: "video-sheet.embed-options.autoPlay.title",
            defaultValue: "ניגון אוטומטי",
            selected: false,
        },
        {
            id: "muted",
            keyName: "video-sheet.embed-options.muted.title",
            defaultValue: "מושתק",
            selected: false,
        },
        {
            id: "captions",
            keyName: "video-sheet.embed-options.captions.title",
            defaultValue: "הפעלת כתוביות",
            selected: false,
        },
        {
            id: "disableSeeking",
            keyName: "video-sheet.embed-options.disableSeeking.title",
            defaultValue: "חסימת ניווט",
            selected: false,
        },
    ]);
    const [isCopied1, setIsCopied1] = useState(false);
    const [isCopied2, setIsCopied2] = useState(false);
    const [loadingPrivacy, setLoadingPrivacy] = useState(false);

    const queryClient = useQueryClient();

    const baseUrl =
        process.env.NEXT_PUBLIC_ENV === "local"
            ? "http://localhost:3000"
            : process.env.NEXT_PUBLIC_ENV === "dev"
            ? "https://dev.app.yedalabs.io"
            : "https://app.yedalabs.io";
    const directPlayUrl = `${baseUrl}/play/${video.pullZone}/${video.id}`;

    const generateEmbedLink = () => {
        const autoPlay = !!embedOptions.find((o) => o.id === "autoPlay")
            ?.selected;
        const muted = !!embedOptions.find((o) => o.id === "muted")?.selected;
        const captions = !!embedOptions.find((o) => o.id === "captions")
            ?.selected;
        const disableSeeking = !!embedOptions.find(
            (o) => o.id === "disableSeeking"
        )?.selected;

        return `${getVideoEmbedUrl(
            video.pullZone!,
            video.id
        )}?autoPlay=${autoPlay}&muted=${muted}&captions=${captions}&disableSeeking=${disableSeeking}`;
    };

    const handleCopy1 = () => {
        if (isCopied1) return;
        navigator.clipboard.writeText(directPlayUrl);
        setIsCopied1(true);
        setTimeout(() => setIsCopied1(false), 2000);
    };

    const handleCopy2 = () => {
        if (isCopied2) return;
        navigator.clipboard.writeText(generateEmbedLink());
        setIsCopied2(true);
        setTimeout(() => setIsCopied2(false), 2000);
    };

    const changeVideoPrivacy = async (privacy: VideoPrivacyType) => {
        setLoadingPrivacy(true);
        await toast.promise(
            client.api.storage.video[":videoId"].update
                .$post({
                    param: { videoId: video.id },
                    json: { privacy },
                })
                .then((res) => {
                    if (res.ok) {
                        queryClient.setQueryData(
                            ["get-video", video.id],
                            (oldData: GetVideoResponseType) => {
                                if (!oldData) return oldData;
                                return {
                                    ...oldData,
                                    data: {
                                        ...oldData.data,
                                        privacy,
                                    },
                                };
                            }
                        );
                    } else throw new Error("Error");
                }),
            {
                loading: "טוען...",
                success: "הגדרות הפרטיות עודכנו בהצלחה!",
                error: "קרתה שגיאה",
            },
            {
                loading: {
                    keyName: "toast.loading.change-video-privacy",
                },
                success: {
                    keyName: "toast.success.change-video-privacy",
                },
                error: {
                    keyName: "toast.error.change-video-privacy",
                },
            }
        );
        setLoadingPrivacy(false);
    };

    return (
        <aside className="flex flex-col w-full lg:w-[300px] xl:w-[400px] py-4 px-8 lg:pe-0 gap-4">
            <div className="bg-white flex flex-col p-4 rounded-md border w-full gap-6">
                <div>
                    <Label>
                        <T
                            keyName="video-sheet.aside.card.select-video-privacy.title"
                            defaultValue="הגדרות פרטיות"
                        />
                    </Label>
                    <Select
                        disabled={loadingPrivacy}
                        value={video.privacy}
                        onValueChange={(value) =>
                            changeVideoPrivacy(value as VideoPrivacyType)
                        }
                        dir="rtl"
                    >
                        <SelectTrigger className="mt-1 h-9 ">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            {privacyOptions.map((option) => (
                                <SelectItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    <div className="flex flex-row items-center gap-2">
                                        <option.icon className="size-4" />
                                        <T
                                            keyName={option.keyName}
                                            defaultValue={option.defaultValue}
                                        />
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label>
                        <T
                            keyName="video-sheet.aside.card.direct-play-link.title"
                            defaultValue="לינק לצפייה ישירה"
                        />
                    </Label>
                    <div className="relative mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={directPlayUrl}
                        />
                        <div
                            className="absolute inset-y-0 end-0 flex items-center pe-3 cursor-pointer"
                            onClick={handleCopy1}
                        >
                            {isCopied1 ? (
                                <Check className="size-4 text-green-500" />
                            ) : (
                                <Copy className="size-4 text-gray-500" />
                            )}
                        </div>
                    </div>
                    {video.privacy !== "public" && (
                        <div className="mt-1 flex flex-row gap-1 text-muted-foreground">
                            <AlertTriangle className="size-3.5" />
                            <p className="text-xs">
                                כדי שהלינק יפעל יש לשנות את הגדרות הפרטיות
                                ל-&quot;פתוח&quot;.
                            </p>
                        </div>
                    )}
                </div>

                <div>
                    <Label>
                        <T
                            keyName="video-sheet.aside.card.embed.title"
                            defaultValue="קוד הטמעה"
                        />
                    </Label>
                    <div className="relative mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={generateEmbedLink()}
                        />
                        <div
                            className="absolute inset-y-0 end-0 flex items-center pe-3 cursor-pointer"
                            onClick={handleCopy2}
                        >
                            {isCopied2 ? (
                                <Check className="size-4 text-green-500" />
                            ) : (
                                <Copy className="size-4 text-gray-500" />
                            )}
                        </div>
                    </div>
                    <div className="mt-2 grid grid-cols-2 gap-1">
                        {embedOptions.map((o) => (
                            <div
                                key={o.id}
                                className="flex flex-row items-center gap-1.5"
                            >
                                <Checkbox
                                    checked={
                                        embedOptions.find(
                                            (opt) => opt.id === o.id
                                        )?.selected
                                    }
                                    onCheckedChange={(checked) => {
                                        setEmbedOptions((prev) =>
                                            prev.map((opt) => ({
                                                ...opt,
                                                selected:
                                                    opt.id === o.id
                                                        ? !opt.selected
                                                        : opt.selected,
                                            }))
                                        );
                                    }}
                                />
                                <Label
                                    onClick={() =>
                                        setEmbedOptions((prev) =>
                                            prev.map((opt) => ({
                                                ...opt,
                                                selected:
                                                    opt.id === o.id
                                                        ? !opt.selected
                                                        : opt.selected,
                                            }))
                                        )
                                    }
                                    className="font-normal"
                                >
                                    <T
                                        keyName={o.keyName}
                                        defaultValue={o.defaultValue}
                                    />
                                </Label>
                            </div>
                        ))}
                    </div>
                </div>

                {/* <div>
                    <Label>
                        <T
                            keyName="video-sheet.aside.card.video-created-at.title"
                            defaultValue="תאריך העלאה"
                        />
                    </Label>
                    <div className="mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={formatIsoStringDate(
                                video.createdAt as string,
                                true
                            )}
                        />
                    </div>
                </div> */}
            </div>
        </aside>
    );
}
