"use client";

import { useMemo } from "react";
import { VideoWorkflowStepName } from "@/app/api/[[...route]]/services/ai/ai-video-workflow/types";
import { useVideoWorkflow } from "./videoWorkflowProvider";
import ProgressState from "./states/progressState";
import GenerateState from "./states/generateState";

export type VideoWorkflowStepProps = {
    stepName: VideoWorkflowStepName;
    texts: {
        title: Message;
        description: Message;
        generateBtn: Message;
    };
};

export type Message = {
    keyName: string;
    defaultValue: string;
};

/**
 * Here we have three modes - "Generate", "Progress" and "Error".
 * We receive as a prop the stepName of the step that renders this component.
 * We then search if this step name exist in the workflow steps.
 * If it does exist, it means the user already generated this step so we show progress.
 * Otherwise we show the "Generate" CTA.
 */
export default function VideoWorkflowStep({
    stepName,
    texts,
}: VideoWorkflowStepProps) {
    const { getWorkflowStepByName } = useVideoWorkflow();

    const step = useMemo(
        () => getWorkflowStepByName(stepName),
        [getWorkflowStepByName, stepName]
    );

    return (
        <div className="bg-card rounded-md border p-6 flex flex-col w-full justify-center items-center">
            {step ? (
                <ProgressState stepName={stepName} texts={texts} />
            ) : (
                <GenerateState stepName={stepName} texts={texts} />
            )}
        </div>
    );
}
