"use client";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { SidebarProvider } from "@/components/ui/sidebar";
import InteractionModalSidebar from "./ui/sidebar";
import InteractionModalMain from "./ui/main";
import { useInteractions } from "../hooks/interactionsProvider";

export default function InteractionModal({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { closeModal } = useInteractions();

    return (
        <Dialog
            open={open}
            onOpenChange={(open) => {
                setOpen(open);
                if (!open) {
                    closeModal();
                }
            }}
        >
            <DialogContent className="p-0 h-fit max-h-[500px] max-w-[600px]">
                <SidebarProvider
                    className="!min-h-0"
                    style={{
                        // @ts-ignore
                        "--sidebar-width": "150px",
                    }}
                >
                    <InteractionModalSidebar />
                    <InteractionModalMain />
                </SidebarProvider>
            </DialogContent>
        </Dialog>
    );
}
