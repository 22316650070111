"use client";

import {
    VideoWorkflowStepName,
    videoWorkflowStepNamesHebrewMapping,
} from "@/app/api/[[...route]]/services/ai/ai-video-workflow/types";
import { useVideoWorkflow } from "../videoWorkflowProvider";
import ErrorState from "./errorState";
import { Progress } from "@/components/ui/progress";
import { T } from "@tolgee/react";
import { VideoWorkflowStepProps } from "../videoWorkflowStep";
import { Sparkles } from "lucide-react";

export default function ProgressState({
    texts,
    stepName,
}: {
    texts: VideoWorkflowStepProps["texts"];
    stepName: VideoWorkflowStepName;
}) {
    const { totalSteps, currentStep } = useVideoWorkflow();

    if (currentStep?.error) {
        return <ErrorState stepName={stepName} />;
    }

    return (
        <>
            <div className="mb-2 flex w-full justify-center items-center">
                <Sparkles className="fill-blue-600 text-blue-600 size-6" />
            </div>
            <h2 className="text-base font-semibold">
                <T
                    keyName={texts.title.keyName}
                    defaultValue={texts.title.defaultValue}
                />
            </h2>
            <p className="mt-0.5 text-sm text-muted-foreground">
                <T
                    keyName={texts.description.keyName}
                    defaultValue={texts.description.defaultValue}
                />
            </p>
            <div className="mt-4 flex w-full flex-col gap-1 items-center">
                <div className="flex w-full flex-row justify-between items-center gap-4">
                    {currentStep?.error ? (
                        <p className="text-xs font-semibold text-red-600">
                            <T
                                keyName={currentStep.error.keyName}
                                defaultValue={currentStep.error.defaultValue}
                            />
                        </p>
                    ) : (
                        <>
                            <p className="text-xs text-muted-foreground">
                                שלב {(currentStep?.index || 0) + 1} מתוך{" "}
                                {totalSteps} -{" "}
                                {currentStep
                                    ? videoWorkflowStepNamesHebrewMapping[
                                          currentStep.name
                                      ]
                                    : ""}
                            </p>
                            <p className="text-xs text-muted-foreground">
                                {currentStep?.progress || 0}%
                            </p>
                        </>
                    )}
                </div>
                <Progress
                    indicatorClassName="bg-blue-600"
                    className="h-2"
                    value={
                        !currentStep || currentStep.error
                            ? 0
                            : currentStep.progress
                    }
                />
            </div>
        </>
    );
}
