"use client";

import { usePayments } from "@/features/payments/hooks/usePayments";
import { calcPlanPrice } from "@/features/payments/lib/paymentsConfig";

export default function PaymentSummary() {
    const selectedPlan = usePayments((state) => state.selectedPlan);

    return (
        <div className="flex flex-col gap-2">
            {/* <h3 className="text-lg font-medium">סיכום</h3> */}
            <dl className="text-xs">
                <div className="flex items-center justify-between pb-1.5">
                    <dt className="">
                        מנוי {selectedPlan?.name}{" "}
                        {selectedPlan?.frequency === "yearly"
                            ? "שנתי"
                            : "חודשי"}
                    </dt>
                    <dd className="">
                        {selectedPlan?.price.planPrice.toFixed(2)}₪
                    </dd>
                </div>
                {!!selectedPlan?.price.discountPercentage && (
                    <div className="flex items-center justify-between py-1.5">
                        <dt className="">
                            {selectedPlan?.price.discountPercentage}% הנחה
                        </dt>
                        <dd className="">
                            <span>
                                {(
                                    (selectedPlan?.price.discountPercentage /
                                        100) *
                                    selectedPlan.price.planPrice
                                ).toFixed(2)}
                                ₪
                            </span>
                            <span>-</span>
                        </dd>
                    </div>
                )}
                {!!selectedPlan?.price.vatPercentage && (
                    <div className="flex items-center justify-between py-1.5">
                        <dt className="">
                            {selectedPlan?.price.vatPercentage}% מע&quot;מ
                        </dt>
                        <dd className="">
                            {(
                                (selectedPlan?.price.vatPercentage / 100) *
                                selectedPlan.price.planPrice
                            ).toFixed(2)}
                            ₪
                        </dd>
                    </div>
                )}
                <div className="flex items-center justify-between pt-1.5 text-sm">
                    <dt className="font-medium">סה&quot;כ לתשלום</dt>
                    <dd className="font-medium">
                        {calcPlanPrice(selectedPlan?.price!)}₪
                    </dd>
                </div>
            </dl>
        </div>
    );
}
