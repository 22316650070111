"use client";

import { Badge } from "@/components/ui/badge";
import { usePayments } from "@/features/payments/hooks/usePayments";

export default function PaymentHeader() {
    const selectedPlan = usePayments((state) => state.selectedPlan);

    return (
        <div>
            <div className="flex flex-row justify-between items-center">
                <h2 className="text-2xl font-semibold">שינוי מנוי</h2>
            </div>
            <p className="text-base text-muted-foreground">
                המנוי הישן יבוטל והמנוי החדש יכנס לתוקף מייד.
            </p>
        </div>
    );
}
