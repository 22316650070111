import { T } from "@tolgee/react";
import { GetVideoResponseType } from "../../../hooks/useGetVideo";
import { Label } from "@/components/ui/label";
import DeleteVideoButton from "./deleteButton";
import DownloadVideoButton from "./downloadButton";
import EditVideoButton from "./editButton";

export default function VideoSheetHeader({
    video,
}: {
    video: GetVideoResponseType["data"];
}) {
    return (
        <header className="bg-white px-8 flex flex-row h-16 w-full justify-between items-center border-b">
            <div className="truncate flex flex-col">
                <Label className="truncate text-base">
                    {video?.title ? (
                        <T
                            keyName="video-sheet.header.title"
                            defaultValue="סרטון: {videoTitle}"
                            params={{
                                videoTitle: video.title,
                            }}
                        />
                    ) : (
                        <T
                            keyName="video-sheet.header.title.placeholder"
                            defaultValue="סרטון ללא שם"
                        />
                    )}
                </Label>

                {video?.collection && (
                    <p className="truncate text-xs text-muted-foreground">
                        <T
                            keyName="video-sheet.header.subtitle"
                            defaultValue="תיקייה: {folderName}"
                            params={{
                                folderName: video.collection.name,
                            }}
                        />
                    </p>
                )}
            </div>

            <div className="hidden md:flex flex-row gap-2">
                <EditVideoButton videoId={video.id} />
                <DownloadVideoButton
                    videoId={video.id}
                    originalFileFormat={video.originalFileFormat}
                />
                <DeleteVideoButton video={video} />
            </div>
        </header>
    );
}
