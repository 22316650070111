import { useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { useCharge } from "../useCharge";
import { LoaderCircle } from "lucide-react";
import CardFields from "./cardFields";
import { Separator } from "@/components/ui/separator";
import PaymentHeader from "./header";
import { usePayments } from "@/features/payments/hooks/usePayments";
import PaymentFormFooter from "./footer";
import PaymentSummary from "./summary";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { calcPlanPrice } from "@/features/payments/lib/paymentsConfig";

declare global {
    interface Window {
        TzlaHostedFields: any;
    }
}

export default function PaymentForm() {
    const [loadedScript, setLoadedScript] = useState(false);
    const fieldsRef = useRef<any>(null);
    const scriptRef = useRef(false);

    const selectedPlan = usePayments((state) => state.selectedPlan);

    useEffect(() => {
        const loadScript = () => {
            scriptRef.current = true;
            const existingScript = document.querySelector(
                "script[src='https://hf.tranzila.com/assets/js/thostedf.js']"
            );
            if (existingScript) existingScript.remove();

            const script = document.createElement("script");
            script.src = "https://hf.tranzila.com/assets/js/thostedf.js";
            script.async = true;
            script.onload = () => setLoadedScript(true);
            document.body.appendChild(script);
        };

        if (!scriptRef.current) {
            loadScript();
        }

        return () => {
            const script = document.querySelector(
                "script[src='https://hf.tranzila.com/assets/js/thostedf.js']"
            );
            if (script) script.remove();
            setLoadedScript(false);
        };
    }, []);

    useEffect(() => {
        const initHostedFields = () => {
            if (fieldsRef.current) return;

            if (loadedScript) {
                console.log("creating hosted fields");

                fieldsRef.current = window.TzlaHostedFields.create({
                    sandbox: false,
                    fields: {
                        credit_card_number: {
                            selector: "#credit_card_number",
                            placeholder: "4580 4580 4580 4580",
                            tabindex: 1,
                        },
                        cvv: {
                            selector: "#cvv",
                            placeholder: "123",
                            tabindex: 3,
                        },
                        expiry: {
                            selector: "#expiry",
                            placeholder: "12/24",
                            version: "1",
                            tabindex: 2,
                        },
                    },
                });
            }
        };

        initHostedFields();
    }, [loadedScript]);

    const {
        handleSubmit,
        loading,
        errorMessage,
        needsInvoice,
        setNeedsInvoice,
    } = useCharge({
        fieldsRef,
        selectedPlan,
    });

    return (
        <div className="flex-1 flex flex-col w-full justify-center gap-4">
            <PaymentHeader />

            <Separator className="h-[0.75px] mb-1" />

            <PaymentSummary />

            <Separator className="h-[0.75px] my-1" />

            <form
                id="payment_form"
                onSubmit={handleSubmit}
                className="flex flex-col w-full gap-4"
            >
                <CardFields needsInvoice={needsInvoice} />

                <div className="mt-1 flex flex-row gap-2 items-center">
                    <Checkbox
                        checked={needsInvoice}
                        onCheckedChange={(checked) =>
                            setNeedsInvoice(!!checked)
                        }
                    />
                    <Label>צריך חשבונית?</Label>
                </div>

                <div className="mt-2">
                    <Button variant="ai" type="submit" className="w-full">
                        {loading ? (
                            <LoaderCircle className="size-4 animate-spin" />
                        ) : (
                            `להצטרפות - ${calcPlanPrice(
                                selectedPlan?.price!
                            ).toLocaleString()}₪ ${
                                selectedPlan?.frequency === "yearly"
                                    ? "בשנה"
                                    : "בחודש"
                            }`
                        )}
                    </Button>

                    {errorMessage && (
                        <p className="mt-2 text-sm text-red-600 font-medium text-center">
                            {errorMessage}
                        </p>
                    )}
                </div>
            </form>

            <div className="-mt-1">
                <PaymentFormFooter />
            </div>
        </div>
    );
}
