"use client";

import { Badge } from "@/components/ui/badge";
import { usePayments } from "@/features/payments/hooks/usePayments";

export default function PaymentHeader() {
    const selectedPlan = usePayments((state) => state.selectedPlan);

    return (
        <div>
            <div className="flex flex-row justify-between items-center">
                <h2 className="text-2xl font-semibold">
                    {/* מנוי {selectedPlan?.name} */}
                    הצטרפות
                </h2>
                {!!selectedPlan?.price.discountPercentage && (
                    <Badge>
                        {selectedPlan?.price.discountPercentage}% הנחה
                    </Badge>
                )}
            </div>
            {/* <p className="text-base text-muted-foreground">
                להצטרפות מלאו את הפרטים שלכם.
            </p> */}
        </div>
    );
}
