import { Button } from "@/components/ui/button";
import { LoaderCircle } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import PaymentHeader from "./header";
import { usePayments } from "@/features/payments/hooks/usePayments";
import PaymentFormFooter from "./footer";
import PaymentSummary from "./summary";
import { calcPlanPrice } from "@/features/payments/lib/paymentsConfig";
import { client } from "@/lib/hono";
import { useState } from "react";
import toast from "@/lib/toast";
import { useRouter } from "next/navigation";

export default function ChangePlan() {
    const [selectedPlan, closePaymentModal] = usePayments((state) => [
        state.selectedPlan,
        state.closePaymentModal,
    ]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const router = useRouter();

    const submitChangePlan = async () => {
        if (!selectedPlan) {
            toast.error("קרתה שגיאה לא צפויה", {
                keyName: "toast.error.default",
            });
            return;
        }

        setLoading(true);
        setErrorMessage("");

        const res = await client.api.payments["change-plan"].$post({
            json: {
                newPlanId: selectedPlan.id,
                clientNewPlanPrice: parseFloat(
                    calcPlanPrice(selectedPlan.price)
                ),
                newPlanFrequency: selectedPlan.frequency,
            },
        });

        if (!res.ok) {
            setLoading(false);
            setErrorMessage(
                "קרתה שגיאה בניסיון שינוי המנוי. צרו קשר עם התמיכה."
            );
        } else {
            toast.success("המנוי עודכן בהצלחה");
            router.refresh();
            closePaymentModal();
        }
    };

    return (
        <div className="flex-1 flex flex-col w-full justify-center gap-4">
            <PaymentHeader />

            <Separator className="h-[0.75px] mb-1" />

            <PaymentSummary />

            <Separator className="h-[0.75px] my-1" />

            <div className="flex flex-col w-full gap-4">
                <div className="mt-2">
                    <Button
                        onClick={submitChangePlan}
                        variant="ai"
                        className="w-full"
                    >
                        {loading ? (
                            <LoaderCircle className="size-4 animate-spin" />
                        ) : (
                            `להצטרפות - ${calcPlanPrice(
                                selectedPlan?.price!
                            ).toLocaleString()}₪ ${
                                selectedPlan?.frequency === "yearly"
                                    ? "בשנה"
                                    : "בחודש"
                            }`
                        )}
                    </Button>

                    {errorMessage && (
                        <p className="mt-2 text-sm text-red-600 font-medium text-center">
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>

            <div className="-mt-1">
                <PaymentFormFooter />
            </div>
        </div>
    );
}
