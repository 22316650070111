"use client";

import {
    VideoWorkflowStepName,
    videoWorkflowStepNamesThatRequireCaptions,
} from "@/app/api/[[...route]]/services/ai/ai-video-workflow/types";
import { Message, VideoWorkflowStepProps } from "../videoWorkflowStep";
import { useState } from "react";
import { useVideoWorkflow } from "../videoWorkflowProvider";
import toast from "@/lib/toast";
import { client } from "@/lib/hono";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectSeparator,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { languages, SrclangType } from "@dev-xsiter/shared-schema";
import { T } from "@tolgee/react";
import { LoaderCircle, Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";

export default function GenerateState({
    stepName,
    texts,
}: {
    stepName: VideoWorkflowStepName;
    texts: VideoWorkflowStepProps["texts"];
}) {
    const [loading, setLoading] = useState(false);
    const [srclang, setSrclang] = useState<SrclangType | undefined>(undefined);
    const { videoId, hasCaptions } = useVideoWorkflow();

    const requireSrclng =
        stepName === "captions" ||
        (videoWorkflowStepNamesThatRequireCaptions.includes(stepName) &&
            !hasCaptions);

    const generate = async () => {
        if (requireSrclng && !srclang) {
            toast.error("בחרו את השפה בה מדברים בסרטון", {
                keyName: "toast.error.generate-captions-no-srclng",
            });
            return;
        }

        setLoading(true);

        const stepNames = [stepName] as VideoWorkflowStepName[];

        const resGenerate = await client.api.ai["video-workflow"].$post({
            json: {
                videoId,
                stepNames,
                srclang: requireSrclng ? srclang : undefined,
            },
        });

        if (!resGenerate.ok) {
            toast.error("קרתה שגיאה", {
                keyName: "toast.error.default",
            });
            setLoading(false);
        }
    };

    return (
        <>
            <div className="mb-2 flex w-full justify-center items-center">
                <Sparkles className="fill-blue-600 text-blue-600 size-6" />
            </div>
            <h2 className="text-base font-semibold">
                <T
                    keyName={texts.title.keyName}
                    defaultValue={texts.title.defaultValue}
                />
            </h2>
            <p className="mt-0.5 text-sm text-muted-foreground">
                <T
                    keyName={texts.description.keyName}
                    defaultValue={texts.description.defaultValue}
                />
            </p>
            <div className="mt-4 flex flex-row items-center gap-2 w-fit">
                {requireSrclng && (
                    <Select
                        value={srclang}
                        onValueChange={(value: SrclangType) =>
                            setSrclang(value)
                        }
                        dir="rtl"
                    >
                        <SelectTrigger className="!w-[250px]">
                            <SelectValue
                                className="!w-[240px]"
                                placeholder="בחרו את השפה בה מדברים בסרטון"
                                placeholderKeyName="video-sheet.captions-tab.generate.select-language.placeholder"
                            />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>
                                    <T
                                        keyName="video-sheet.captions-tab.generate.select-language.menu-label"
                                        defaultValue="בחרו את השפה בסרטון"
                                    />
                                </SelectLabel>
                                <SelectSeparator />
                                {languages.map((l) => (
                                    <SelectItem key={l.code} value={l.code}>
                                        {l.label}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                )}
                <Button
                    variant="ai"
                    size="default"
                    onClick={generate}
                    className="flex flex-row gap-2 items-center"
                >
                    {loading ? (
                        <LoaderCircle className="size-4 animate-spin" />
                    ) : (
                        <T
                            keyName={texts.generateBtn.keyName}
                            defaultValue={texts.generateBtn.defaultValue}
                        />
                    )}
                </Button>
            </div>
        </>
    );
}
