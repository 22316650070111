"use client";

import { client } from "@/lib/hono";
import { useVideoWorkflow } from "../videoWorkflowProvider";
import toast from "@/lib/toast";
import { T } from "@tolgee/react";
import { LoaderCircle, TriangleAlert } from "lucide-react";
import { Button, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useState } from "react";
import {
    VideoWorkflowStepName,
    videoWorkflowStepNamesHebrewMapping,
} from "@/app/api/[[...route]]/services/ai/ai-video-workflow/types";

export default function ErrorState({
    stepName,
}: {
    stepName: VideoWorkflowStepName;
}) {
    const { currentStep, workflowRetries, videoId } = useVideoWorkflow();
    const [loadingRetry, setLoadingRetry] = useState(false);

    const error = currentStep?.error!;

    const contactSupport = () => {
        console.log("didnt find intercom");
    };

    const retryFailedWorkflow = async () => {
        console.log({ workflowRetries });

        setLoadingRetry(true);

        const res = await client.api.ai["retry-video-workflow"].$post({
            json: {
                videoId,
            },
        });

        if (!res.ok) {
            toast.error("ניסיון חוזר נכשל", {
                keyName: "toast.error.retry-failed-video-workflow",
            });
            setLoadingRetry(false);
        } else {
            toast.success("ניסיון חוזר הופעל בהצלחה!", {
                keyName: "toast.success.retry-failed-video-workflow",
            });
        }
    };

    return (
        <>
            <div className="mb-2 flex w-full justify-center items-center">
                <TriangleAlert className="text-destructive size-8" />
            </div>
            <h2 className="text-base font-semibold">
                <T keyName={error.keyName} defaultValue={error.defaultValue} />
            </h2>
            <p className="mt-0.5 text-sm text-muted-foreground">
                נסיון {videoWorkflowStepNamesHebrewMapping[stepName]} נכשל. בחר
                איך תרצה להמשיך.
            </p>
            <div className="mt-4 flex w-full flex-col gap-1 items-center">
                <div className="flex flex-row gap-4">
                    {error.openPaymentsModal && (
                        <a
                            href="/pricing"
                            className={cn(
                                buttonVariants({ size: "sm", variant: "ai" })
                            )}
                        >
                            <T
                                keyName="video-workflow-step.error-state.ctas.upgrade-plan"
                                defaultValue="שדרוג המנוי"
                            />
                        </a>
                    )}
                    <Button
                        onClick={retryFailedWorkflow}
                        disabled={loadingRetry}
                        size="sm"
                        variant="secondary"
                    >
                        {loadingRetry ? (
                            <LoaderCircle className="size-4 animate-spin" />
                        ) : (
                            <T
                                keyName="video-workflow-step.error-state.ctas.retry"
                                defaultValue="ניסיון חוזר"
                            />
                        )}
                    </Button>
                    <Button
                        onClick={contactSupport}
                        size="sm"
                        variant="secondary"
                    >
                        <T
                            keyName="video-workflow-step.error-state.ctas.help"
                            defaultValue="עזרה"
                        />
                    </Button>
                </div>
            </div>
        </>
    );
}
