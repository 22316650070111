import { Button } from "@/components/ui/button";
import { useInteractions } from "../../hooks/interactionsProvider";
import { interactionModalSteps } from "../../lib/utils";
import { T } from "@tolgee/react";

export default function ContinueButton() {
    const { nextStep, currentStep, selectedInteraction, closeModal } =
        useInteractions();

    const currentStepIndex = interactionModalSteps.findIndex(
        (s) => s.id === currentStep
    );

    const isLastStep =
        !!selectedInteraction &&
        interactionModalSteps.every((step, idx) => {
            if (idx <= currentStepIndex) return true;

            if (!step.showForTypes) return false;

            return !step.showForTypes.includes(selectedInteraction.type);
        });

    return (
        <div className="flex w-full justify-end">
            <Button
                className="w-fit"
                onClick={() => {
                    if (isLastStep) {
                        closeModal();
                    } else {
                        nextStep();
                    }
                }}
            >
                <T
                    keyName={`interaction-modal.submit-button.${
                        isLastStep ? "finish" : "continue"
                    }`}
                    defaultValue={isLastStep ? "סיום" : "המשך"}
                />
            </Button>
        </div>
    );
}
