import { create } from "zustand";
import { SelectedPlan } from "../types";

type Step = "PaymentWall" | "PaymentForm" | "ChangePlan";

interface PaymentsStore {
    currentStep: Step;
    selectedPlan: SelectedPlan | null;
    paymentModalOpen: boolean;
    setPaymentModalOpen: (
        open: boolean,
        step: Step,
        plan?: SelectedPlan | null
    ) => void;
    closePaymentModal: () => void;
}

export const usePayments = create<PaymentsStore>((set, get) => ({
    currentStep: "PaymentWall",
    selectedPlan: null,
    paymentModalOpen: false,
    setPaymentModalOpen: (
        open: boolean,
        step: Step,
        plan: SelectedPlan | null = null
    ) =>
        set(() => ({
            paymentModalOpen: open,
            currentStep: step,
            selectedPlan: plan,
        })),
    closePaymentModal: () => {
        set(() => ({
            paymentModalOpen: false,
        }));
    },
}));
