export const posthogEvents = {
    signup: "user_signed_up",
    completeOnboarding: "user_completed_onboarding",
    createSyllabus: "user_created_syllabus",
    createPresentation: "user_created_persentation",
    uploadVideos: "user_uploaded_videos",
    paymentTrigger: "user_encountered_payment_trigger",
    initiatePayment: "user_initiated_payment",
    purchaseSubscription: "user_purchased_subscription",
};
