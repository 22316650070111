"use client";

import { Label } from "@/components/ui/label";
import CardField from "./cardField";
import { Input } from "@/components/ui/input";

export default function CardFields({
    needsInvoice,
}: {
    needsInvoice: boolean;
}) {
    return (
        <>
            <CardField
                id="credit_card_number"
                label="מספר כרטיס"
                className="flex flex-col w-full"
            />
            <div className="flex flex-row w-full gap-3">
                <CardField
                    id="expiry"
                    label="תוקף"
                    className="flex flex-col w-2/3"
                />
                <CardField
                    id="cvv"
                    label="cvv"
                    className="flex flex-col w-1/3"
                />
            </div>
            {needsInvoice && (
                <div className="flex flex-row gap-3">
                    <div className="flex flex-col gap-1">
                        <Label>שם על החשבונית</Label>
                        <Input id="nameForInvoice" name="nameForInvoice" />
                    </div>
                    <div className="flex flex-col gap-1">
                        <Label>ח&quot;פ</Label>
                        <Input id="idForInvoice" name="idForInvoice" />
                    </div>
                </div>
            )}
        </>
    );
}
