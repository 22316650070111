import { CredentialsSignin } from "next-auth";

export const loginPath = "/auth/login";
export const onboardingPath = "/onboarding";
export const verifyRequestPath = "/auth/verify-request";
export const homePath = "/home";

export type AuthErrorCode =
    | "email_not_found"
    | "invalid_credentials"
    | "password_login_not_available";

export class EmailNotFound extends CredentialsSignin {
    code = "email_not_found" satisfies AuthErrorCode;
}

export class InvalidCredentials extends CredentialsSignin {
    code = "invalid_credentials" satisfies AuthErrorCode;
}

export class PasswordLoginUnavailable extends CredentialsSignin {
    code = "password_login_not_available" satisfies AuthErrorCode;
}

export const PUBLIC_ROUTES = [
    "/play/",
    "/images/",
    "/illustrations/",
    "/auth/",
    "/public/",
    "/logos/",
];
export const MOBILE_NOT_SUPPORTED_ROUTES = [
    "/recorder/",
    "/edit-video/",
    "/courses/",
    "/assistants",
];
