import { LockClosedIcon } from "@heroicons/react/24/solid";
import Image from "next/image";

const cards = [
    {
        src: "/images/payments/visa.png",
        alt: "visa",
    },
    {
        src: "/images/payments/mc.png",
        alt: "mastercard",
    },
    {
        src: "/images/payments/amex.png",
        alt: "amex",
    },
    {
        src: "/images/payments/diners.png",
        alt: "diners",
    },
];
export default function PaymentFormFooter() {
    return (
        <div className="text-xs text-center text-gray-900 gap-2 w-full flex flex-col justify-center items-center">
            <p className="flex flex-row gap-1 items-center justify-center">
                <LockClosedIcon className="size-4" />
                <span>100% מהעסקאות מאובטחות ומוצפנות בתקן PCI</span>
            </p>
            <div className="flex flex-row gap-2">
                {cards.map((card) => (
                    <Image
                        key={card.src}
                        width={50}
                        height={50}
                        className="rounded-md border-2 aspect-video"
                        src={card.src}
                        alt={card.alt}
                    />
                ))}
            </div>
            <p className="text-muted-foreground">
                אנחנו מכבדים את פרטיותך. בלחיצה על &quot;להצטרפות&quot;
                <br />
                את/ה מאשר/ת את{" "}
                <a
                    href="https://www.yedatech.io"
                    target="_blank"
                    className="underline"
                >
                    תנאי השימוש ומדיניות הפרטיות בפלטפורמה
                </a>
                .
            </p>
        </div>
    );
}

{
    /* <Image
src="/logos/tranzila.png"
alt="tranzila"
width={107.5} // {430}
height={29.25} // {117}
quality={100}
/> */
}
