import { InteractionStep } from "./types";
import {
    LucideIcon,
    ListChecks,
    CircleHelp,
    Settings,
    SlidersHorizontal,
} from "lucide-react";
import { VideoInteractionType } from "@dev-xsiter/shared-schema";

export const interactionModalSteps: {
    id: InteractionStep;
    keyName: string;
    defaultValue: string;
    uniqueTextPerType?: Partial<
        Record<VideoInteractionType, { keyName: string; defaultValue: string }>
    >;
    showForTypes?: VideoInteractionType[];
    icon: LucideIcon;
}[] = [
    {
        id: "time-and-type",
        keyName: "interaction-modal.sidebar.time-and-type",
        defaultValue: "זמן וסוג",
        icon: SlidersHorizontal,
    },
    {
        id: "question",
        keyName: "interaction-modal.sidebar.question",
        defaultValue: "שאלה",
        uniqueTextPerType: {
            rating: {
                keyName: "interaction-modal.sidebar.rating",
                defaultValue: "דירוג",
            },
            form: {
                keyName: "interaction-modal.sidebar.form",
                defaultValue: "טופס",
            },
            link: {
                keyName: "interaction-modal.sidebar.link",
                defaultValue: "לינק",
            },
        },
        icon: CircleHelp,
    },
    {
        id: "answers",
        keyName: "interaction-modal.sidebar.answers",
        defaultValue: "תשובות",
        showForTypes: ["open", "close"],
        icon: ListChecks,
    },
    {
        id: "settings",
        keyName: "interaction-modal.sidebar.settings",
        defaultValue: "הגדרות",
        showForTypes: ["open", "close", "form", "rating"],
        icon: Settings,
    },
];

export const interactionTypeOptions: {
    id: VideoInteractionType;
    keyName: string;
    defaultValue: string;
}[] = [
    {
        id: "open",
        keyName: "interaction-modal.select-type.open",
        defaultValue: "שאלה פתוחה",
    },
    {
        id: "close",
        keyName: "interaction-modal.select-type.closed",
        defaultValue: "שאלה סגורה",
    },
    {
        id: "form",
        keyName: "interaction-modal.select-type.form",
        defaultValue: "טופס",
    },
    {
        id: "rating",
        keyName: "interaction-modal.select-type.rating",
        defaultValue: "דירוג",
    },
    {
        id: "link",
        keyName: "interaction-modal.select-type.link",
        defaultValue: "לינק",
    },
];
