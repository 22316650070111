"use client";

import { LoaderCircle } from "lucide-react";
import { useGetCaptions } from "../../../../hooks/useGetCaptions";
import EditCaptions from "./editCaptions";
import VideoWorkflowStep from "../videoWorkflow/videoWorkflowStep";
import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";

export default function CaptionsTab({
    captionsId,
    setReloadVideoKey,
}: {
    captionsId: string;
    setReloadVideoKey: (key: number) => void;
}) {
    const { isPending, data } = useGetCaptions(captionsId);

    if (captionsId && isPending) {
        return (
            <div className="flex w-full py-24 justify-center items-center">
                <LoaderCircle className="size-5 animate-spin" />
            </div>
        );
    }

    if (data?.data?.srt) {
        return (
            <EditCaptions
                captionsId={data.data.id}
                srt={data.data.srt}
                srclang={data.data.srclang}
                setReloadVideoKey={setReloadVideoKey}
            />
        );
    }

    return (
        <div className="mt-4">
            <div className="mb-6">
                <Label className="">
                    <T
                        keyName="video-sheet.captions-tab.title"
                        defaultValue="כתוביות"
                    />
                </Label>
                <p className="text-sm text-muted-foreground">
                    <T
                        keyName="video-sheet.captions-tab.description"
                        defaultValue="הוסיפו כתוביות לסרטון שלכם."
                    />
                </p>
            </div>
            <VideoWorkflowStep
                stepName="captions"
                texts={{
                    title: {
                        keyName: "video-sheet.create-captions-with-ai.title",
                        defaultValue: "יצירת כתוביות עם AI",
                    },
                    description: {
                        keyName:
                            "video-sheet.create-captions-with-ai.description",
                        defaultValue:
                            "צרו כתוביות מדוייקות ברמה הגבוהה ביותר לסרטון שלכם",
                    },
                    generateBtn: {
                        keyName: "video-sheet.generate-captions-with-ai-btn",
                        defaultValue: "יצירה",
                    },
                }}
            />
        </div>
    );
}
