"use client";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import UploadFile, { FileType } from "@/components/utils/uploadFile";
import { T } from "@tolgee/react";
import { useState } from "react";
import { GetVideoResponseType } from "../../../hooks/useGetVideo";
import { Button } from "@/components/ui/button";
import { LoaderCircle } from "lucide-react";
import { useStorage } from "@/features/storage/useStorage";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { useQueryClient } from "@tanstack/react-query";
import { Checkbox } from "@/components/ui/checkbox";

export default function GeneralTab({
    video,
    setReloadVideoKey,
}: {
    video: GetVideoResponseType["data"];
    setReloadVideoKey: (key: number) => void;
}) {
    const [thumbnailUrl, setThumbnailUrl] = useState(video.thumbnailUrl || "");
    const [title, setTitle] = useState(video.title || "");
    const [collection, setCollection] = useState(video.collection);
    const [disableSeeking, setDisableSeeking] = useState(
        !!video.disableSeeking
    );
    const [showEndScreen, setShowEndScreen] = useState(!!video.showEndScreen);

    const [changes, setChanges] = useState(false);
    const [loading, setLoading] = useState(false);

    const openStorageExplorer = useStorage((state) => state.setExplorerOpen);

    const queryClient = useQueryClient();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setLoading(true);

        const res = await client.api.storage.video[":videoId"].update.$post({
            param: { videoId: video.id },
            json: {
                thumbnailUrl,
                title,
                collectionId: collection?.id || undefined,
                disableSeeking,
                showEndScreen,
            },
        });

        const resJson = await res.json();
        console.log({ resJson });

        if (!res.ok) {
            toast.error("אירעה שגיאה בניסיון עדכון סרטון", {
                keyName: "toast.error.default",
            });
            setLoading(false);
            return;
        }

        toast.success("השינויים נשמרו בהצלחה!", {
            keyName: "toast.success.save-changes",
        });

        queryClient.invalidateQueries({ queryKey: ["get-storage-files"] });
        queryClient.invalidateQueries({
            queryKey: ["get-video", video.id],
        });
        setReloadVideoKey(Date.now());

        setLoading(false);
        setChanges(false);
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="mt-5 flex flex-col w-full gap-6"
        >
            <div className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col w-full md:w-1/2 gap-2">
                    <Label htmlFor="title">
                        <T
                            keyName="video-sheet.general-tab.video-name.label"
                            defaultValue="שם הסרטון"
                        />
                    </Label>
                    <Input
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            setChanges(true);
                        }}
                        className="mt-0.5"
                        id="title"
                        required
                    />
                </div>
                <div className="flex flex-col w-full md:w-1/2 gap-2">
                    <Label htmlFor="selectCollection">
                        <T
                            keyName="video-sheet.select-collection.label"
                            defaultValue="בחירת תיקייה"
                        />
                    </Label>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="flex h-9 w-full rounded-md border border-input bg-background px-2.5 py-2 text-sm ring-offset-background  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0">
                            {collection?.name || ""}
                        </div>
                        <Button
                            type="button"
                            onClick={() => {
                                openStorageExplorer(true, {
                                    description: {
                                        keyName:
                                            "storage-explorer.video-sheet.choose-collection",
                                        defaultValue:
                                            "בחרו תיקייה שבה תרצו לשמור את הסרטון.",
                                    },
                                    collections: {
                                        onCollectionClick: (coll) => {
                                            setChanges(true);
                                            setCollection({
                                                id: coll.id,
                                                name: coll.name || "",
                                            });
                                            openStorageExplorer(false);
                                        },
                                        showCreateCollectionButton: true,
                                    },
                                });
                            }}
                            variant="secondary"
                            size="default"
                        >
                            <T
                                keyName="video-sheet.choose-collection.choose-btn"
                                defaultValue="בחירה"
                            />
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <Label>
                    <T
                        keyName="video-sheet.general-tab.thumbnail.label"
                        defaultValue="תמונה ממוזערת (Thumbnail)"
                    />
                </Label>
                <UploadFile
                    options={{
                        allowedFileTypes: [FileType.Images],
                        fileUploadPath: `videos/${video.id}/thumbnails`,
                        overrideFileName: "thumbnail",
                        maxFileSize: 4,
                        className: "bg-white h-48 mt-1",
                        showImagePreview: true,
                        purgeCache: true,
                    }}
                    initialFile={
                        video.thumbnailUrl
                            ? {
                                  fileName: "thumbnail",
                                  fileUrl: video.thumbnailUrl,
                              }
                            : undefined
                    }
                    onUploadFinished={(image) => {
                        setChanges(true);
                        setThumbnailUrl(image.fileUrl);
                    }}
                />
            </div>
            <div className="flex flex-col gap-2 my-2">
                <div className="flex flex-row gap-2 items-center">
                    <Checkbox
                        checked={showEndScreen}
                        onCheckedChange={(checked) => {
                            setChanges(true);
                            setShowEndScreen(!!checked);
                        }}
                    />
                    <Label
                        onClick={() => {
                            setChanges(true);
                            setShowEndScreen((prev) => !prev);
                        }}
                    >
                        <T
                            keyName="video-sheet.general-tab.end-screen-checkbox.label"
                            defaultValue="הצגת מסך סיום"
                        />
                    </Label>
                </div>
                <div className="flex flex-row gap-2 items-center">
                    <Checkbox
                        checked={disableSeeking}
                        onCheckedChange={(checked) => {
                            setChanges(true);
                            setDisableSeeking(!!checked);
                        }}
                    />
                    <Label
                        onClick={() => {
                            setChanges(true);
                            setDisableSeeking((prev) => !prev);
                        }}
                    >
                        <T
                            keyName="video-sheet.general-tab.disable-seeking-checkbox.label"
                            defaultValue="חסימת אפשרות ניווט"
                        />
                    </Label>
                </div>
            </div>
            <Button
                className="w-fit"
                type="submit"
                disabled={loading || !changes}
            >
                {loading ? (
                    <LoaderCircle className="size-4 text-white animate-spin" />
                ) : (
                    <T
                        keyName="video-sheet.general-tab.save-btn"
                        defaultValue="שמירת שינויים"
                    />
                )}
            </Button>
        </form>
    );
}
